import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPassword, signin } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import "../styles/signin.css";
import Button from "../components/Button";
import { USER_FORGOT_RESET } from "../constants/userConstants";

export default function ForgotPasswordScreen(props) {
    const initialState = { email: "" };
    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const redirect = props.location.search
        ? props.location.search.split("=")[1]
        : "/";

    const passwordForgot = useSelector((state) => state.passwordForgot);
    const { success, loading, error } = passwordForgot;

    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(forgotPassword(formData));
    };
    useEffect(() => {
        if (success) {
            window.alert("Reset Email Sent");
            dispatch({ type: USER_FORGOT_RESET });
            props.history.push("/");
        }
    }, [dispatch, props.history, success]);

    return (
        <div>
            <nav className="flex items-center justify-start pl-8 py-4 w-full  border-b-2">
                <h1 className=" hover:text-gray-400 text-3xl font-bold">
                    <Link to="/">{global.config.websiteName}</Link>
                </h1>
            </nav>
            <form
                className="form-signin"
                style={{ marginTop: "10rem", marginBottom: "10rem" }}
                onSubmit={submitHandler}
            >
                <div className="h1-message">
                    <h1>Forgot Password</h1>
                    <p className="p-message">
                        Enter your email address to proceed.
                    </p>
                </div>

                <div className="field">
                    <input
                        className="input-signin"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="example@gmail.com"
                        onChange={handleChange}
                    />
                    <label className="label-signin" htmlFor="email">
                        Email
                    </label>
                    {error && (
                        <MessageBox varient="danger">
                            {error.message}
                        </MessageBox>
                    )}
                </div>

                <div className="button-center">
                    <Button
                        buttonName="Send Reset Code"
                        buttonType="submit"
                        buttonClass="login-button"
                        loading={loading}
                        disabled={loading}
                    />
                </div>

                <p className="p-message">
                    Don’t need to reset? &nbsp;
                    <Link
                        to={`/signin?redirect=${redirect}`}
                        className="text-orange-450"
                    >
                        Signin Here
                    </Link>
                </p>
            </form>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { signup } from "../actions/userActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

import { ReactComponent as CheckMark } from "../assets/images/checkMark.svg";
import Button from "../components/Button";

export default function RegisterScreen(props) {
    const initialState = {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        mobileNumber: "",
    };
    const [formData, setFormData] = useState(initialState);
    const [onPasswordEnter, setOnPasswordEnter] = useState(false);
    const [nameValidate, setNameValidate] = useState("");
    const [passwordValidate, setPasswordValidate] = useState(false);
    const [validateEmail, setValidateEmail] = useState("");
    const [validateConfirmPass, setValidateConfirmPass] = useState("");
    const [validateMobile, setValidateMobile] = useState("");

    const userRegister = useSelector((state) => state.userRegister);
    const { userInfo, loading, error } = userRegister;
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            alert("Passwords entered do not match ");
        } else {
            dispatch(signup(formData));
        }
    };

    const redirect = props.location.search
        ? props.location.search.split("=")[1]
        : "/";

    useEffect(() => {
        if (userInfo) {
            props.history.push(redirect);
        }
    }, [props.history, redirect, userInfo]);

    const is_int = (value) => {
        if (parseFloat(value) === parseInt(value) && !isNaN(value)) {
            return true;
        } else {
            return false;
        }
    };

    const validateNameFunc = (value) => {
        if (value.length < 1) {
            setNameValidate("You must have a name");
        } else if (value.length > 29) {
            setNameValidate(
                "I don't know how to put this. \n Your name is a bit too long. \n just like this message.",
            );
        } else {
            setNameValidate("");
        }
    };

    const validatePassword = (value) => {
        if (value.length < 6) {
            setPasswordValidate("Password should be 6 or more characters");
        } else if (value.length > 30) {
            setPasswordValidate(
                "Mighty long password you've got there. Could it maybe be a bit shorter. 30 characters should be secure enough. ",
            );
        } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(value)) {
            setPasswordValidate("Enter a valid password");
        } else {
            setPasswordValidate("");
        }
    };

    const validateEmailFunc = (value) => {
        if (value.length < 1) {
            setValidateEmail("Email is required.");
        } else if (!/^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/.test(value)) {
            setValidateEmail("🤔 enter a valid email");
        } else {
            setValidateEmail("");
        }
    };
    const validateConfirmPassFunc = (value) => {
        if (value.length < 1) {
            setValidateConfirmPass("You need to confirm your password");
        } else if (value !== formData.password) {
            setValidateConfirmPass("Passwords should match");
        } else {
            setValidateConfirmPass("");
        }
    };
    const validateMobileFunc = (value) => {
        if (!is_int(value)) {
            setValidateMobile("Letters and Numbers don't mix");
        } else if (value.length !== 10 && is_int(value)) {
            setValidateMobile("Enter a valid 10 digit Mobile No.");
        } else {
            setValidateMobile("");
        }
    };

    return (
        <div>
            <nav className="flex items-center justify-start pl-8 py-4 w-full  border-b-2">
                <h1 className=" hover:text-gray-400 text-3xl font-bold">
                    <Link to="/">{global.config.websiteName}</Link>
                </h1>
            </nav>
            <form
                className="form-signin"
                style={{ marginTop: "2rem" }}
                onSubmit={submitHandler}
                autoComplete="on"
            >
                <div className="info-signup">
                    <h1 className="h1-signup-message ">
                        Sign Up to {global.config.websiteName}
                    </h1>
                    <p className="p-signup-message text-semibold text-gray-700">
                        You're one step away from Packaging <br></br> that meets
                        your design.
                    </p>
                </div>
                <div className="field">
                    <input
                        className="input-signin"
                        name="name"
                        type="text"
                        id="name"
                        placeholder="Enter your Name"
                        onChange={handleChange}
                        required
                        autoFocus
                        min="4"
                        max="30"
                        onBlurCapture={(e) => validateNameFunc(e.target.value)}
                        autoComplete="name"
                    />
                    <label className="label-signin" htmlFor="name">
                        What is your name?
                    </label>
                    {error && <MessageBox varient="danger">{error}</MessageBox>}
                </div>
                {nameValidate && <p className="errorMsg">{nameValidate}</p>}

                <div className="field">
                    <input
                        className="input-signin"
                        type="text"
                        autoComplete="email"
                        id="email"
                        name="email"
                        pattern="^[^@ ]+@[^@ ]+\.[^@ .]{2,}$"
                        min="4"
                        max="30"
                        placeholder="Enter your E-mail"
                        onChange={handleChange}
                        onBlurCapture={(e) => validateEmailFunc(e.target.value)}
                        required
                    />
                    <label className="label-signin" htmlFor="email">
                        Email
                    </label>
                </div>
                {validateEmail && <p className="errorMsg">{validateEmail}</p>}

                <div className="field">
                    <input
                        className="input-signin"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        onFocus={() => setOnPasswordEnter(true)}
                        onBlur={() => setOnPasswordEnter(false)}
                        onBlurCapture={(e) => validatePassword(e.target.value)}
                        onChange={handleChange}
                        min="6"
                        max="30"
                        required
                    />
                    <label className="label-signin" htmlFor="password">
                        Create a Password
                    </label>
                </div>
                {passwordValidate && (
                    <p className="errorMsg">{passwordValidate}</p>
                )}

                <div className="field">
                    {onPasswordEnter ? (
                        <div className="errTooltip">
                            {formData.password.length < 6 ? (
                                <p>
                                    <CheckMark className="errCheckmark " />6 or
                                    more characters
                                </p>
                            ) : (
                                <p className="successp">
                                    <CheckMark className="errCheckmark " />6 or
                                    more characters
                                </p>
                            )}
                            {!/[A-Z]/.test(formData.password) ? (
                                <p>
                                    <CheckMark className="errCheckmark" /> Upper
                                    case and Lowercase letters
                                </p>
                            ) : (
                                <p className="successp">
                                    <CheckMark className="errCheckmark" /> Upper
                                    case and Lowercase letters
                                </p>
                            )}
                            {!/[0-9]/.test(formData.password) ? (
                                <p>
                                    <CheckMark className="errCheckmark" /> At
                                    least one number
                                </p>
                            ) : (
                                <p className="successp">
                                    <CheckMark className="errCheckmark" /> At
                                    least one number
                                </p>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="field">
                    <input
                        className="input-signin"
                        type="password"
                        name="confirmPassword"
                        minLength="6"
                        id="confirmPassword"
                        placeholder="Re-enter Password"
                        autoComplete="new-password"
                        onChange={handleChange}
                        onBlurCapture={(e) =>
                            validateConfirmPassFunc(e.target.value)
                        }
                        required
                    />

                    <label className="label-signin" htmlFor="confirmPassword">
                        Confirm Password
                    </label>
                </div>
                {validateConfirmPass && (
                    <p className="errorMsg">{validateConfirmPass}</p>
                )}

                <div className="field">
                    <input
                        className="input-signin"
                        name="mobileNumber"
                        type="tel"
                        pattern="^[0-9]{3,45}$"
                        id="mobile"
                        autoComplete="tel-national"
                        placeholder="Enter your 10 digit Mobile No."
                        onChange={handleChange}
                        onBlurCapture={(e) =>
                            validateMobileFunc(e.target.value)
                        }
                        min="10"
                        max="10"
                        required
                    />

                    <label className="label-signin" htmlFor="mobile">
                        What is your Mobile No.
                    </label>
                </div>
                {validateMobile && <p className="errorMsg">{validateMobile}</p>}

                <div className="button-center">
                    <Button
                        buttonName="Sign Up"
                        buttonType="submit"
                        buttonClass="signup-button"
                        loading={loading}
                        disabled={loading}
                    />
                </div>
                <p className="p-message">
                    Already have an account? &nbsp;
                    <Link
                        to={`/signin?redirect=${redirect}`}
                        className="text-orange-450"
                    >
                        Sign-In
                    </Link>
                </p>
            </form>
        </div>
    );
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function QuantitySelector({
    qty,
    setQty,
    countInStock,
    isStock,
    type,
    product,
    personalised,
    selectorType,
    setButtonSelected,
}) {
    const dispatch = useDispatch();
    const increment = () => {
        if (qty < countInStock || isStock) {
            setQty(qty + 1);
            setButtonSelected(5);
        }
    };
    const decrement = () => {
        if (qty > 1) {
            setQty(qty - 1);
            setButtonSelected(5);
        }
    };
    const [updateQty, setUpdateQty] = useState(qty);
    const incrementCart = () => {
        if (updateQty < countInStock || isStock) {
            setUpdateQty(updateQty + 1);
        }

        // dispatch(updateCartQty(product, Number(updateQty)));
    };

    // useEffect(() => {
    //     if (selectorType === 1 ) {
    //         dispatch(updateCartQty(product, Number(updateQty)));
    //     } else {
    //         dispatch(addToCart(product, Number(updateQty), personalised));
    //     }
    // }, [dispatch, updateQty]);
    const decrementCart = () => {
        if (updateQty > 1) {
            setUpdateQty(updateQty - 1);
        }
    };

    const selectorSwitch = (type) => {
        switch (type) {
            case 1:
                return cartSelector;
            default:
                return productSelector;
        }
    };

    const productSelector = (
        <div className="quantity-input">
            <button
                className="quantity-input__modifier quantity-input__modifier--left"
                onClick={decrement}
            >
                &mdash;
            </button>
            <input
                className="quantity-input__screen"
                type="text"
                value={qty}
                readOnly
            />
            <button
                className="quantity-input__modifier quantity-input__modifier--right"
                onClick={increment}
            >
                &#xff0b;
            </button>
        </div>
    );
    const cartSelector = (
        <div className="quantity-input">
            <button
                className="quantity-input__modifier quantity-input__modifier--left"
                onClick={decrementCart}
            >
                &mdash;
            </button>
            <input
                className="quantity-input__screen"
                type="text"
                value={updateQty}
                readOnly
            />
            <button
                className="quantity-input__modifier quantity-input__modifier--right"
                onClick={incrementCart}
            >
                &#xff0b;
            </button>
        </div>
    );

    return <div className="quatity-body ">{selectorSwitch(selectorType)}</div>;
}

import React from "react";

const usePrevious = (value) => {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};
const useRequestAnimationFrame = (cb) => {
    const handles = React.useRef([]);
    const _raf = (cb) => {
        handles.current.push(requestAnimationFrame(cb));
    };
    const _resetRaf = () => {
        handles.current.forEach((id) => cancelAnimationFrame(id));
        handles.current = [];
    };

    return [_raf, _resetRaf];
};

const CrossFadeImage = (props) => {
    const { src, alt, transitionDuration = 0.35, curve = "ease" } = props;
    const oldSrc = usePrevious(src);
    const [topSrc, setTopSrc] = React.useState(src);
    const [bottomSrc, setBottomSrc] = React.useState("");
    const [bottomOpacity, setBottomOpacity] = React.useState(0);
    const [display, setDisplay] = React.useState(false);
    const [raf, resetRaf] = useRequestAnimationFrame();

    React.useEffect(() => {
        if (src !== oldSrc) {
            resetRaf();
            setTopSrc("");
            setBottomSrc("");

            raf(() => {
                setTopSrc(src);
                setBottomSrc(oldSrc);
                setBottomOpacity(99);

                raf(() => {
                    setBottomOpacity(0);
                });
            });
        }
    }, [oldSrc, raf, resetRaf, src]);

    return (
        <div
            className="flex justify-center items-center"
            style={{
                position: "relative",
                minHeight: "100%",
                height: "100%",
            }}
        >
            {topSrc && (
                <img
                    style={{
                        position: "absolute",
                        opacity: display ? "100%" : 0,
                        transition: `opacity ${transitionDuration}s ${curve}`,
                    }}
                    onLoad={() => setDisplay(true)}
                    className="object-cover md:max-w-5xl xl:max-w-4xl 2xl:max-w-5xl"
                    src={topSrc}
                    alt={alt}
                />
            )}
            {bottomSrc && (
                <img
                    style={{
                        position: "absolute",
                        opacity: bottomOpacity + "%",
                        transition: `opacity ${transitionDuration}s ${curve}`,
                    }}
                    className="object-cover md:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl"
                    src={bottomSrc}
                    alt={alt}
                    loop="infinite"
                />
            )}
        </div>
    );
};

export default CrossFadeImage;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import config from "./config";
import ReactGA from "react-ga";
import SwitchContainer from "./SwitchContainer";
import ScrollToTop from "./components/ScrollToTop";

ReactGA.initialize(config.GOOGLE_ANALYTICS);

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <SwitchContainer />
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;

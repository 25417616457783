import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { removeFromCart } from "../actions/cartActions";
import MessageBox from "../components/MessageBox";
import Modal from "../components/Modal";
import Button from "../components/Button";

export default function CartScreen(props) {
    const cart = useSelector((state) => state.cart);
    const { cartItems, error } = cart;
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalDescription, setModalDescription] = useState("");

    const history = useHistory();

    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id));
    };

    const checkoutHandler = () => {
        history.push("/signin?redirect=shipping");
        props.setOpen(false);
    };
    const personalisedHandler = (e) => {
        setModalDescription(e);
        setModalIsOpen(true);
    };

    return (
        <div className="page-animate">
            <div className="flex flex-col justify-center mt-10 mb-40 ">
                <div className="flex flex-wrap flex-col items-center md:flex-row md:items-start mt-10 justify-center">
                    {cartItems.length === 0 ? (
                        <div className="grid place-items-center">
                            <h1 className="heading-1 text-blue-300">
                                Your basket is empty.{" "}
                            </h1>
                            <Link
                                className="text-gray-400 hover:text-blue-400"
                                to="/search"
                                onClick={() => props.setOpen(false)}
                            >
                                Visit our Store
                            </Link>
                        </div>
                    ) : (
                        <>
                            <div className="overflow-hidden">
                                <h1 className="  text-2xl text-black font-semibold mb-6">
                                    You have{" "}
                                    <span className="text-orange-450 ">
                                        {cartItems.reduce(
                                            (a, c) => a + c.qty,
                                            0,
                                        )}
                                    </span>{" "}
                                    Boxes in your Cart
                                </h1>
                                {error && (
                                    <MessageBox variant="danger">
                                        {error}
                                    </MessageBox>
                                )}

                                <ul>
                                    {cartItems.map((item, index) => (
                                        <li key={index}>
                                            <div className="flex flex-wrap justify-center flex-row overflow-hidden  md:justify-start mb-10">
                                                <div>
                                                    {loaded ? null : (
                                                        <div
                                                            className=" rounded-2xl bg-blue-400 animate-pulse"
                                                            style={{
                                                                width: "20rem",
                                                                height: "20rem",
                                                            }}
                                                        />
                                                    )}
                                                    <img
                                                        style={
                                                            loaded
                                                                ? {
                                                                      objectFit:
                                                                          "contain",
                                                                      height: "20rem",
                                                                      width: "20rem",
                                                                  }
                                                                : {
                                                                      display:
                                                                          "none",
                                                                  }
                                                        }
                                                        src={item.image}
                                                        alt={item.name}
                                                        height="200"
                                                        width="200"
                                                        className="rounded-2xl "
                                                        onLoad={() =>
                                                            setLoaded(true)
                                                        }
                                                    />
                                                </div>

                                                <div className="flex flex-row overflow-hidden pl-2 pt-2 md:pl-12">
                                                    <div>
                                                        <h2 className="text-xl font-medium mb-2 truncate max-w-md">
                                                            <Link
                                                                to={`/product/${item.slug}`}
                                                                onClick={() =>
                                                                    props.setOpen(
                                                                        false,
                                                                    )
                                                                }
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        </h2>
                                                        <div className="flex flex-row">
                                                            <div>
                                                                <div>
                                                                    <div className="text-base leading-tight font-light">
                                                                        <p>
                                                                            Price:
                                                                            ₹
                                                                            {
                                                                                item.price
                                                                            }{" "}
                                                                            each
                                                                        </p>
                                                                        <p>
                                                                            Dimensions:{" "}
                                                                            {
                                                                                item.len
                                                                            }{" "}
                                                                            x{" "}
                                                                            {
                                                                                item.width
                                                                            }{" "}
                                                                            x{" "}
                                                                            {
                                                                                item.height
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            Material:{" "}
                                                                            {
                                                                                item.material
                                                                            }
                                                                        </p>
                                                                        {item &&
                                                                            item.personalised && (
                                                                                <>
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() =>
                                                                                            personalisedHandler(
                                                                                                <>
                                                                                                    <p>
                                                                                                        <b>
                                                                                                            {" "}
                                                                                                            Message:
                                                                                                        </b>{" "}
                                                                                                        {item
                                                                                                            .personalised
                                                                                                            ?.message
                                                                                                            ? `${item.personalised?.message}`
                                                                                                            : " No message Added"}
                                                                                                    </p>
                                                                                                    <p>
                                                                                                        <b>
                                                                                                            Image:
                                                                                                        </b>
                                                                                                        {item.imageAdded
                                                                                                            ? ` Image Added`
                                                                                                            : " No Image Added"}
                                                                                                    </p>
                                                                                                </>,
                                                                                            )
                                                                                        }
                                                                                        className="flex items-center text-blue-900 hover:text-blue-500"
                                                                                    >
                                                                                        <svg
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            height="15"
                                                                                            width="15"
                                                                                            viewBox="0 0 20 20"
                                                                                            fill="currentColor"
                                                                                            className="mr-2"
                                                                                        >
                                                                                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                                                            <path
                                                                                                fillRule="evenodd"
                                                                                                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                                                                                clipRule="evenodd"
                                                                                            />
                                                                                        </svg>
                                                                                        Personalised
                                                                                        Details
                                                                                    </button>
                                                                                </>
                                                                            )}
                                                                        <p className=" md:py-2 lg:py-4 font-semibold">
                                                                            ₹
                                                                            {item.price *
                                                                                item.qty}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <button
                                                                    className="text-sm"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        removeFromCartHandler(
                                                                            item.id,
                                                                        )
                                                                    }
                                                                >
                                                                    <strong>
                                                                        remove
                                                                    </strong>
                                                                </button>
                                                            </div>
                                                            <div className=" ml-10 lg:ml-20 text-base font mt-6 md:mt-6">
                                                                <div>
                                                                    {/* <QuantitySelector
                                                                        qty={
                                                                            item.qty
                                                                        }
                                                                        countInStock={
                                                                            item.countInStock
                                                                        }
                                                                        type={1}
                                                                        product={
                                                                            item.id
                                                                        }
                                                                        personalised={
                                                                            item.personalised
                                                                        }
                                                                        isStock={
                                                                            item.inStock
                                                                        }
                                                                        selectorType={
                                                                            1
                                                                        }
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="w-full   absolute  bottom-0 left-0  pr-2">
                                <div className="">
                                    <div className="rounded-2xl  shadow-2xl   border-t-2 py-8 px-14 pulse-slow bg-gray-50">
                                        <div className="">
                                            <div className="flex justify-between items-center  w-full">
                                                <div className=" w-3/6">
                                                    <p className="text-sm leading-tight font-normal text-gray-500">
                                                        Shipping to be
                                                        calculated
                                                    </p>
                                                    <h1 className="py-2 text-lg font-semibold">
                                                        Subtotal (
                                                        {cartItems.reduce(
                                                            (a, c) => a + c.qty,
                                                            0,
                                                        )}{" "}
                                                        items) : ₹
                                                        {cartItems.reduce(
                                                            (a, c) =>
                                                                a +
                                                                c.price * c.qty,
                                                            0,
                                                        )}
                                                    </h1>
                                                </div>
                                                <div className="w-3/6">
                                                    <Button
                                                        buttonName="Checkout"
                                                        buttonType="button"
                                                        buttonClass="Button text-2xl font-medium w-full noselect "
                                                        onClickHandler={
                                                            checkoutHandler
                                                        }
                                                        disabled={
                                                            cartItems.length ===
                                                            0
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Modal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                title="Details"
                description={modalDescription}
            />
        </div>
    );
}

import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundScreen() {
    return (
        <>
            <div className="min-w-screen min-h-screen bg-blue-100 flex items-center p-5 lg:p-20 overflow-hidden relative">
                <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800 relative text-center">
                    <div className="w-full md:w-1/2">
                        <div className="mb-10 lg:mb-20"></div>
                        <div className="mb-10 md:mb-20 text-gray-600 font-light ">
                            <h1 className="font-black uppercase text-3xl lg:text-5xl text-orange-500 mb-10">
                                You seem to be lost!
                            </h1>
                            <p>The page you're looking for isn't available.</p>
                            <p>
                                Try searching again use the search button below.
                            </p>
                        </div>
                        <div className="mb-20 md:mb-0">
                            <Link
                                to="/search"
                                className="text-lg font-light outline-none focus:outline-none transform transition-all hover:scale-110 text-orange-500 hover:text-orange-600"
                            >
                                <i className="mdi mdi-arrow-left mr-2"></i>Go
                                Shop
                            </Link>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 text-center"></div>
                </div>
                <div className="w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform"></div>
                <div className="w-96 h-full bg-orange-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform"></div>
            </div>
        </>
    );
}

import Axios from "axios";
import {
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_CATEGORY_LIST_REQUEST,
    PRODUCT_CATEGORY_LIST_SUCCESS,
    PRODUCT_CATEGORY_LIST_FAIL,
    PRODUCT_REVIEW_CREATE_FAIL,
    PRODUCT_REVIEW_CREATE_SUCCESS,
    PRODUCT_REVIEW_CREATE_REQUEST,
    PRODUCT_REVIEW_DELETE_REQUEST,
    PRODUCT_REVIEW_DELETE_SUCCESS,
    PRODUCT_REVIEW_DELETE_FAIL,
    PRODUCT_CATEGORY_FILTEREDLIST_FAIL,
    PRODUCT_CATEGORY_FILTEREDLIST_SUCCESS,
    PRODUCT_CATEGORY_FILTEREDLIST_REQUEST,
    PRODUCT_DETAILS_RESET,
} from "../constants/productConstants";

export const listProducts =
    ({
        pageNumber = "",
        seller = "",
        name = "",
        category = "",
        min = 0,
        max = 0,
        rating = 0,
        order = "",
        pageSize = "",
    }) =>
    async (dispatch) => {
        dispatch({
            type: PRODUCT_LIST_REQUEST,
        });
        try {
            //data loaded into frontend
            const { data } = await Axios.get(
                `/api/products?pageNumber=${pageNumber}&seller=${seller}&name=${name}&category=${category}&min=${min}&max=${max}&rating=${rating}&order=${order}&pageSize=${pageSize}`,
            );
            dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
        } catch (error) {
            dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message });
        }
    };

export const listProductCategories = () => async (dispatch) => {
    dispatch({
        type: PRODUCT_CATEGORY_LIST_REQUEST,
    });
    try {
        //data loaded into frontend
        const { data } = await Axios.get(`/api/products/categories`);
        dispatch({ type: PRODUCT_CATEGORY_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PRODUCT_CATEGORY_LIST_FAIL, payload: error.message });
    }
};

export const listCategoriesFiltered = () => async (dispatch) => {
    dispatch({
        type: PRODUCT_CATEGORY_FILTEREDLIST_REQUEST,
    });
    try {
        //data loaded into frontend
        const { data } = await Axios.post(`/api/products/categoriesFiltered`);
        dispatch({
            type: PRODUCT_CATEGORY_FILTEREDLIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_CATEGORY_FILTEREDLIST_FAIL,
            payload: error.message,
        });
    }
};

export const detailsProduct = (slug) => async (dispatch) => {
    dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: slug });
    try {
        const { data } = await Axios.get(`/api/products/${slug}`);
        dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const createReview =
    (productId, review) => async (dispatch, getState) => {
        dispatch({ type: PRODUCT_REVIEW_CREATE_REQUEST });
        const {
            userSignin: { userInfo },
        } = getState();

        try {
            const { data } = await Axios.post(
                `/api/products/${productId}/reviews`,
                review,
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                },
            );
            dispatch({
                type: PRODUCT_REVIEW_CREATE_SUCCESS,
                payload: data.review,
            });
        } catch (error) {
            const message =
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message;
            dispatch({ type: PRODUCT_REVIEW_CREATE_FAIL, payload: message });
        }
    };

export const deleteReview =
    (productId, reviewId) => async (dispatch, getState) => {
        dispatch({
            type: PRODUCT_REVIEW_DELETE_REQUEST,
            payload: productId,
        });
        const {
            userSignin: { userInfo },
        } = getState();
        try {
            const { data } = await Axios.delete(
                `/api/products/${productId}/reviews/${reviewId}`,
                {
                    headers: { Authorization: `Bearer ${userInfo.token}` },
                },
            );
            dispatch({ type: PRODUCT_REVIEW_DELETE_SUCCESS });
        } catch (error) {
            const message =
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message;
            dispatch({ type: PRODUCT_REVIEW_DELETE_FAIL, payload: message });
        }
    };

import React from "react";

import PaymentMethodScreen from "./screens/PaymentMethodScreen";
import OrderScreen from "./screens/OrderScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ShippingAddressScreen from "./screens/ShippingAddressScreen";
import SigninScreen from "./screens/SigninScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import { DefaultContainer } from "./DefaultContainer";
import { Switch, Route } from "react-router-dom";
import usePageViews from "./usePageViews";
import ErrorBoundary from "./components/ErrorBoundary";

const RouteWithErrorBoundary = (props) => {
    return (
        <ErrorBoundary key={props.location?.pathname}>
            <Route {...props} />
        </ErrorBoundary>
    );
};

export default function SwitchContainer() {
    usePageViews();
    return (
        <Switch>
            <RouteWithErrorBoundary path="/signin" component={SigninScreen} />
            <RouteWithErrorBoundary
                path="/register"
                component={RegisterScreen}
            />
            <RouteWithErrorBoundary
                path="/forgot-password"
                component={ForgotPasswordScreen}
            />
            <RouteWithErrorBoundary
                path="/reset-password"
                component={ResetPasswordScreen}
                exact
            />
            <RouteWithErrorBoundary
                path="/shipping"
                component={ShippingAddressScreen}
            />
            <RouteWithErrorBoundary
                path="/payment"
                component={PaymentMethodScreen}
            />
            <RouteWithErrorBoundary
                path="/order/:id"
                component={OrderScreen}
                exact
            />
            <RouteWithErrorBoundary component={DefaultContainer} />
        </Switch>
    );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { googleAuth, signin } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import GoogleLogin from "react-google-login";
import "../styles/signin.css";
import Button from "../components/Button";

export default function SigninScreen(props) {
    const initialState = { email: "", password: "" };
    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const redirect = props.location.search
        ? props.location.search.split("=")[1]
        : "/";

    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo, loading, error } = userSignin;

    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(signin(formData));
    };
    useEffect(() => {
        if (userInfo) {
            props.history.push(redirect);
        }

        return () => {};
    }, [props.history, redirect, userInfo]);

    const googleSuccess = (res) => {
        console.log(res);

        const result = res?.profileObj;
        const token = res?.tokenId;
        try {
            dispatch(googleAuth(result, token));
            console.log(result);
        } catch (error) {
            console.log(error);
        }
    };
    const googleFailure = (error) => {
        console.log(error);
        console.log("Sign In was unsuccesful");
    };

    return (
        <div>
            <nav className="flex items-center justify-start pl-8 py-4 w-full  border-b-2">
                <h1 className=" hover:text-gray-400 text-3xl font-bold">
                    <Link to="/">{global.config.websiteName}</Link>
                </h1>
            </nav>
            <form
                className="form-signin"
                style={{ marginTop: "10rem", marginBottom: "10rem" }}
                onSubmit={submitHandler}
            >
                <div className="h1-message">
                    <h1>Sign In</h1>
                    <p className="p-message">Please Sign In to proceed.</p>
                </div>

                <div className="button-center">
                    <GoogleLogin
                        clientId={global.config.GOOGLE_SIGNIN_KEY}
                        onSuccess={googleSuccess}
                        onFailure={googleFailure}
                        className=" rounded-3xl px-4 "
                        cookiePolicy={"single_host_origin"}
                        render={(renderProps) => (
                            <button
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                                className="flex items-center justify-between  login-button "
                                style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "solid black 2px",
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 48 48"
                                    width="30px"
                                    height="30px"
                                >
                                    <path
                                        fill="#FFC107"
                                        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                                    />
                                    <path
                                        fill="#FF3D00"
                                        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                                    />
                                    <path
                                        fill="#4CAF50"
                                        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                                    />
                                    <path
                                        fill="#1976D2"
                                        d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                                    />
                                </svg>
                                <span>Sign in with Google </span>
                            </button>
                        )}
                    />
                </div>
                <div className="field flex items-center py-4 font-bold text-2xl">
                    or
                </div>

                <div className="field">
                    <input
                        className="input-signin"
                        type="email"
                        name="email"
                        id="email"
                        placeholder="example@gmail.com"
                        onChange={handleChange}
                    />
                    <label className="label-signin" htmlFor="email">
                        Email
                    </label>
                    {error && <MessageBox varient="danger">{error}</MessageBox>}
                </div>

                <div className="field">
                    <input
                        className="input-signin"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        required
                        onChange={handleChange}
                    />
                    <label className="label-signin" htmlFor="password">
                        Password
                    </label>
                </div>
                <Link
                    to="/forgot-password"
                    className="text-orange-450 hover:text-red-400 py-1 field text-right"
                >
                    {" "}
                    Forgot Password?{" "}
                </Link>

                <div className="button-center">
                    <Button
                        buttonName="Sign In"
                        buttonType="submit"
                        buttonClass="login-button"
                        loading={loading}
                        disabled={loading}
                    />
                </div>

                <p className="p-message">
                    Don’t have an account? &nbsp;
                    <Link
                        to={`/register?redirect=${redirect}`}
                        className="text-orange-450"
                    >
                        Register Here
                    </Link>
                </p>
            </form>
        </div>
    );
}

import Axios from "axios";
import {
    CART_ADD_ITEM,
    CART_ADD_ITEM_FAIL,
    CART_ADD_ITEM_SUCCESS,
    CART_QTY_UPDATE_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_PAYMENT_METHOD,
    CART_SAVE_SHIPPING_ADDRESS,
} from "../constants/cartConstants";

// export const updateCartQty = (cartId, qty) => async (dispatch, getState) => {
//     const {
//         cart: { cartItems },
//     } = getState();

//     dispatch({ type: CART_QTY_UPDATE_ITEM, payload: { id: cartId, qty: qty } });
//     localStorage.setItem(
//         "cartItems",
//         JSON.stringify(getState().cart.cartItems),
//     );
// };

export const addToCart =
    (slug, qty, personalised, svg, imageAdded) =>
    async (dispatch, getState) => {
        try {
            const { data } = await Axios.get(`/api/products/${slug}`);
            const {
                cart: { cartItems },
            } = getState();

            dispatch({
                type: CART_ADD_ITEM,
                payload: {
                    id: Date.now() + Math.random(),
                    name: data.name,
                    slug: data.slug,
                    image: data.image,
                    price: imageAdded ? data.price + 100 : data.price,
                    countInStock: data.countInStock,
                    inStock: data.inStock,
                    product: data._id,
                    productCategory: data.category,
                    seller: data.seller,
                    typeOfProduct: data.typeOfProduct,
                    len: data.specs.len,
                    width: data.specs.width,
                    height: data.specs.height,
                    material: data.specs.material,
                    qty,
                    personalised,
                    svgLink: svg,
                    imageAdded,
                },
            });
            localStorage.setItem(
                "cartItems",
                JSON.stringify(getState().cart.cartItems),
            );
            dispatch({ type: CART_ADD_ITEM_SUCCESS });
        } catch (error) {
            console.log(error);
        }
    };

// export const addToCartCustom =
//     (productId, qty) => async (dispatch, getState) => {
//         const { data } = await Axios.get(`/api/custombox/${productId}`);
//         dispatch({
//             type: CART_ADD_CUSTOMBOX,
//             payload: {
//                 name: data.name,
//                 image: data.image,
//                 price: data.price,
//                 countInStock: data.countInStock,
//                 product: data._id,
//                 qty,
//                 typeOfProduct: data.typeOfProduct,
//                 len: data.length,
//                 width: data.width,
//                 height: data.height,
//                 material: data.material,
//             },
//         });
//         localStorage.setItem(
//             "cartItems",
//             JSON.stringify(getState().cart.cartItems)
//         );
//     };

export const removeFromCart = (cartId) => (dispatch, getState) => {
    dispatch({ type: CART_REMOVE_ITEM, payload: cartId });
    localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems),
    );
};

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({ type: CART_SAVE_SHIPPING_ADDRESS, payload: data });
    localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
    //dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data });
    dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data });
};

import {
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_CATEGORY_LIST_REQUEST,
    PRODUCT_CATEGORY_LIST_SUCCESS,
    PRODUCT_CATEGORY_LIST_FAIL,
    PRODUCT_REVIEW_CREATE_RESET,
    PRODUCT_REVIEW_CREATE_FAIL,
    PRODUCT_REVIEW_CREATE_SUCCESS,
    PRODUCT_REVIEW_CREATE_REQUEST,
    PRODUCT_REVIEW_DELETE_REQUEST,
    PRODUCT_REVIEW_DELETE_SUCCESS,
    PRODUCT_REVIEW_DELETE_FAIL,
    PRODUCT_REVIEW_DELETE_RESET,
    PRODUCT_CATEGORY_FILTEREDLIST_FAIL,
    PRODUCT_CATEGORY_FILTEREDLIST_SUCCESS,
    PRODUCT_CATEGORY_FILTEREDLIST_REQUEST,
    PRODUCT_DETAILS_RESET,
} from "../constants/productConstants";

export const productListReducer = (
    state = { loading: true, products: [] },
    action
) => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
            return { loading: true };
        case PRODUCT_LIST_SUCCESS:
            return {
                loading: false,
                products: action.payload.products,
                pages: action.payload.pages,
                page: action.payload.page,
            };
        case PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productCategoryListReducer = (
    state = { loading: true, products: [] },
    action
) => {
    switch (action.type) {
        case PRODUCT_CATEGORY_LIST_REQUEST:
            return { loading: true };
        case PRODUCT_CATEGORY_LIST_SUCCESS:
            return { loading: false, categories: action.payload };
        case PRODUCT_CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productCategoryFilteredListReducer = (
    state = { loading: true, categories: [] },
    action
) => {
    switch (action.type) {
        case PRODUCT_CATEGORY_FILTEREDLIST_REQUEST:
            return { loading: true };
        case PRODUCT_CATEGORY_FILTEREDLIST_SUCCESS:
            return { loading: false, categories: action.payload };
        case PRODUCT_CATEGORY_FILTEREDLIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return { loading: true };
        case PRODUCT_DETAILS_SUCCESS:
            return { loading: false, product: action.payload };
        case PRODUCT_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const productReviewCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_REVIEW_CREATE_REQUEST:
            return { loading: true };
        case PRODUCT_REVIEW_CREATE_SUCCESS:
            return { loading: false, success: true, review: action.payload };
        case PRODUCT_REVIEW_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_REVIEW_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const productReviewDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_REVIEW_DELETE_REQUEST:
            return { loading: true };
        case PRODUCT_REVIEW_DELETE_SUCCESS:
            return { loading: false, success: true };
        case PRODUCT_REVIEW_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case PRODUCT_REVIEW_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

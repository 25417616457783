export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";

export const GOOGLE_AUTH_REQUEST = "GOOGLE_AUTH_REQUEST";
export const GOOGLE_AUTH_SUCCESS = "GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "GOOGLE_AUTH_FAIL";
export const GOOGLE_AUTH_SIGNOUT = "GOOGLE_AUTH_SIGNOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_SIGNOUT = "USER_SIGNOUT";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_TOPSELLER_LIST_REQUEST = "USER_TOPSELLER_LIST_REQUEST";
export const USER_TOPSELLER_LIST_SUCCESS = "USER_TOPSELLER_LIST_SUCCESS";
export const USER_TOPSELLER_LIST_FAIL = "USER_TOPSELLER_LIST_FAIL";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_FORGOT_REQUEST = "USER_FORGOT_REQUEST";
export const USER_FORGOT_SUCCESS = "USER_FORGOT_SUCCESS";
export const USER_FORGOT_FAIL = "USER_FORGOT_FAIL";
export const USER_FORGOT_RESET = "USER_FORGOT_RESET";

export const USER_RESET_REQUEST = "USER_RESET_REQUEST";
export const USER_RESET_SUCCESS = "USER_RESET_SUCCESS";
export const USER_RESET_FAIL = "USER_RESET_FAIL";
export const USER_RESET_RESET = "USER_RESET_RESET";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { savePaymentMethod } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";

// import { Container } from './styles';

export default function PaymentMethodScreen(props) {
	const cart = useSelector((state) => state.cart);
	const { shippingAddress } = cart;
	if (!shippingAddress.address) {
		props.history.push("/shipping");
	}
	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo } = userSignin;
	if (!userInfo) {
		props.history.push("/signin");
	}

	const [paymentMethod, setPaymentMethod] = useState("OnlinePayment");
	const dispatch = useDispatch();
	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(savePaymentMethod(paymentMethod));
		props.history.push("/placeorder");
	};
	return (
		<div>
			<CheckoutSteps step1 step2 step3></CheckoutSteps>
			<form className="form" onSubmit={submitHandler}>
				<div>
					<h1>Payment</h1>
				</div>
				<div>
					<div>
						<input
							type="radio"
							id="online"
							value="OnlinePayment"
							name="paymentMethod"
							required
							checked
							onChange={(e) => setPaymentMethod(e.target.value)}
						></input>
						<label htmlFor="online">Online Payment</label>
					</div>
				</div>
				<div>
					<input
						type="radio"
						id="cod"
						value="COD"
						name="paymentMethod"
						required
						onChange={(e) => setPaymentMethod(e.target.value)}
					></input>
					<label htmlFor="cod">Cash On Delivery</label>
				</div>
				<div>
					<button className="primary" type="submit">
						Continue
					</button>
				</div>
			</form>
		</div>
	);
}

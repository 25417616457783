module.exports = global.config = {
    websiteName: "Indiekrate",
    i18n: {
        welcome: {
            en: "Welcome",
            fa: "خوش آمدید",
        },
        // rest of your translation object
    },
    // other global config variables you wish
    RECAPCHA_SITE_KEY: "6LfTr8QcAAAAAAFMmrL4AsGHIv1cbZOm0qWADgJO",
    GOOGLE_SIGNIN_KEY:
        "8927451986-8hga0r51np0gljom447iht404pmmakhq.apps.googleusercontent.com",
    GOOGLE_FONTS_API_KEY: "AIzaSyDFpwNxK2yVaVWqRgQsYj1XvcAW5sREFvE",
    GOOGLE_ANALYTICS: "UA-210675633-1",
    SENTRY_DSN:
        "https://9ff2dfae177c4caaa19e9f6af53c2fbe@o1053412.ingest.sentry.io/6037874",
};

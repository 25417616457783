import {
	BRAND_LIST_FAIL,
	BRAND_LIST_REQUEST,
	BRAND_LIST_SUCCESS,
	BRAND_DETAILS_REQUEST,
	BRAND_DETAILS_SUCCESS,
	BRAND_DETAILS_FAIL,

} from "../constants/brandConstants";

export const brandListReducer = (state = { loading: true }, action) => {
	switch (action.type) {
		case BRAND_LIST_REQUEST:
			return { loading: true };
		case BRAND_LIST_SUCCESS:
			return { loading: false, brand: action.payload };
		case BRAND_LIST_FAIL:
			return { loading: false, error: action.payload };

		default:
			return state;
	}
};

export const brandDetailsReducer = (state = { loading: true }, action) => {
	switch (action.type) {
		case BRAND_DETAILS_REQUEST:
			return { loading: true };
		case BRAND_DETAILS_SUCCESS:
			return { loading: false, brand: action.payload };
		case BRAND_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../node_modules/axios/index";
import { savePaymentMethod, saveShippingAddress } from "../actions/cartActions";
import NavbarSecondry from "../components/NavbarSecondry";
import "../styles/shippingScreen.css";
import { ReactComponent as CheckMark } from "../assets/images/checkMark.svg";
import Button from "../components/Button";
import {
    createAddress,
    deleteAddress,
    listAddresses,
    updateAddress,
} from "../actions/addressActions";
import { Disclosure } from "@headlessui/react";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import MessageBox from "../components/MessageBox";

export default function ShippingAddressScreen(props) {
    const dispatch = useDispatch();
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    const cart = useSelector((state) => state.cart);
    const { shippingAddress } = cart;

    if (!userInfo) {
        props.history.push("/signin");
    }
    const [fullName, setFullName] = useState(shippingAddress.fullName || "");
    const [addressLine1, setAddressLine1] = useState(
        shippingAddress.addressLine1 || "",
    );
    const [addressLine2, setAddressLine2] = useState(
        shippingAddress.addressLine2 || "",
    );
    const [landmark, setLandmark] = useState(shippingAddress.landmark || "");
    const [city, setCity] = useState(shippingAddress.city || "");
    const [postalCode, setPostalCode] = useState(
        shippingAddress.postalCode || "",
    );
    const [state, setState] = useState(shippingAddress.state || "");
    const [country, setCountry] = useState(shippingAddress.country || "India");
    const [contactNo, setContactNo] = useState(shippingAddress.contactNo || "");
    const [paymentMethod, setPaymentMethod] = useState("OnlinePayment");
    const [notReal, setNotReal] = useState(false);
    const [mobileVaidateError, setmobileVaidateError] = useState(false);
    const [onMobileEnter, setonMobileEnter] = useState(false);

    //Place Order Start
    const orderCreate = useSelector((state) => state.orderCreate);
    const {
        loading: loadingPlaceOrder,
        success: successPlaceOrder,
        error: errorPlaceOrder,
        order,
    } = orderCreate;
    const toPrice = (num) => Number(num.toFixed(2));
    cart.itemsPrice = toPrice(
        cart.cartItems.reduce((a, c) => a + c.qty * c.price, 0),
    );

    //shipping
    //cart.shippingPrice = cart.itemsPrice > 400 ? toPrice() : toPrice(100);
    cart.shippingPrice = toPrice(
        cart.cartItems.reduce((a, c) => a + c.qty * 60, 0),
    );
    //tax
    cart.taxPrice = toPrice(0 * cart.itemsPrice);
    cart.totalPrice = (
        cart.itemsPrice +
        cart.shippingPrice +
        cart.taxPrice
    ).toFixed(2);

    const [shippingInfoStored, setShippingInfoStored] = useState(false);
    useEffect(() => {
        if (successPlaceOrder) {
            props.history.push(`/order/${order._id}`);
            dispatch({ type: ORDER_CREATE_RESET });
        }
    }, [dispatch, order, props.history, successPlaceOrder]);

    useEffect(() => {
        if (shippingInfoStored) {
            dispatch(createOrder({ ...cart, orderItems: cart.cartItems }));
            setShippingInfoStored(false);
        }
    }, [dispatch, successPlaceOrder, shippingInfoStored]);

    //Place Order End

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            saveShippingAddress({
                fullName,
                addressLine1,
                addressLine2,
                landmark,
                city,
                postalCode,
                state,
                country,
                contactNo,
            }),
        );

        dispatch(savePaymentMethod(paymentMethod));
        setShippingInfoStored(true);
    };

    const [locationKeys, setLocationKeys] = useState([]);
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === "PUSH") {
                setLocationKeys([location.key]);
            }

            if (history.action === "POP") {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);

                    // Handle forward event
                } else {
                    setLocationKeys((keys) => [location.key, ...keys]);

                    props.history.push("/cart");
                }
            }
        });
    }, [history, locationKeys, props.history]);

    const [showAddressField2, setShowAddressField2] = useState(false);
    const ShowAddressField2 = (e) => {
        e.preventDefault();
        setShowAddressField2(!showAddressField2);
    };

    const is_int = (value) => {
        if (parseFloat(value) === parseInt(value) && !isNaN(value)) {
            return true;
        } else {
            return false;
        }
    };

    const validateMobileNumber = () => {
        if (contactNo.length === 10 && is_int(contactNo)) {
            setmobileVaidateError(true);
        } else {
            setmobileVaidateError(false);
        }
    };

    return (
        <div>
            <NavbarSecondry step1 step2 />

            <div className="bg-white">
                {/* Background color split screen for large screens */}
                <div
                    className="hidden lg:block fixed top-26 left-0 w-1/2 h-full bg-white"
                    aria-hidden="true"
                />
                <div
                    className="hidden lg:block fixed top-0 right-0 w-1/2 h-full bg-orange-450"
                    aria-hidden="true"
                />

                <div className="relative grid grid-cols-1 gap-x-16 max-w-8xl mx-auto lg:px-8 lg:grid-cols-2 lg:pt-16">
                    <h1 className="sr-only">Checkout</h1>
                    {/* Mobile order summary */}
                    <section
                        aria-labelledby="order-heading"
                        className="bg-orange-450 px-4 py-6 sm:px-6 md:hidden"
                    >
                        <Disclosure as="div" className="max-w-2xl mx-auto">
                            {({ open }) => (
                                <>
                                    <div className="flex items-center justify-between">
                                        <h2
                                            id="order-heading"
                                            className="text-lg font-medium text-red-100"
                                        >
                                            Order summary
                                        </h2>
                                        <Disclosure.Button className="font-medium text-red-100 hover:text-gray-700">
                                            {open ? (
                                                <span>Hide full summary</span>
                                            ) : (
                                                <span>Show full summary</span>
                                            )}
                                        </Disclosure.Button>
                                    </div>

                                    <Disclosure.Panel>
                                        <ul
                                            role="list"
                                            className="divide-y divide-gray-200 border-b border-gray-200"
                                        >
                                            {cart &&
                                                cart.cartItems.map(
                                                    (product) => (
                                                        <li
                                                            key={product.id}
                                                            className="flex items-start py-6 space-x-4"
                                                        >
                                                            <img
                                                                src={
                                                                    product.image
                                                                }
                                                                alt={
                                                                    product.name
                                                                }
                                                                className="flex-none w-28 h-28 rounded-md object-center object-contain bg-gray-50 "
                                                            />
                                                            <div className="flex-auto space-y-1 text-white">
                                                                <h3 className="text-white">
                                                                    {
                                                                        product.name
                                                                    }
                                                                </h3>
                                                                <p>
                                                                    {
                                                                        product.material
                                                                    }
                                                                </p>
                                                                <p>
                                                                    {
                                                                        product.len
                                                                    }{" "}
                                                                    x{" "}
                                                                    {
                                                                        product.width
                                                                    }{" "}
                                                                    x{" "}
                                                                    {
                                                                        product.height
                                                                    }{" "}
                                                                </p>
                                                            </div>
                                                            <p className="flex-none text-base font-medium text-white">
                                                                {product.qty} x
                                                                ₹{product.price}
                                                                = ₹
                                                                {product.qty *
                                                                    product.price}
                                                            </p>
                                                        </li>
                                                    ),
                                                )}
                                        </ul>

                                        <dl className="text-sm font-medium text-red-100 mt-10 space-y-6">
                                            <div className="flex justify-between">
                                                <dt>
                                                    Subtotal (
                                                    {cart.cartItems.reduce(
                                                        (a, c) => a + c.qty,
                                                        0,
                                                    )}{" "}
                                                    items)
                                                </dt>
                                                <dd className="text-gray-100">
                                                    ₹{cart.itemsPrice}
                                                </dd>
                                            </div>

                                            <div className="flex justify-between">
                                                <dt>Taxes</dt>
                                                <dd className="text-gray-100">
                                                    ₹{cart.taxPrice}
                                                </dd>
                                            </div>
                                            <div className="flex justify-between">
                                                <dt>Shipping</dt>
                                                <dd className="text-gray-100">
                                                    ₹{cart.shippingPrice}
                                                </dd>
                                            </div>
                                        </dl>
                                    </Disclosure.Panel>

                                    <p className="flex items-center justify-between text-sm font-semibold text-gray-100 border-t border-gray-100 pt-6 mt-6">
                                        <span className="text-base">Total</span>
                                        <span className="text-base">
                                            ₹{cart.totalPrice}
                                        </span>
                                    </p>
                                </>
                            )}
                        </Disclosure>
                    </section>
                    {/* end mobile */}

                    <section
                        aria-labelledby="summary-heading"
                        className="hidden md:block bg-orange-450 text-red-100 py-12 md:px-10 lg:max-w-2xl lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24 lg:bg-transparent lg:row-start-1 lg:col-start-2"
                    >
                        <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
                            <h2 id="summary-heading" className="sr-only">
                                Order summary
                            </h2>

                            <dl>
                                <dt className="text-sm font-medium">
                                    Amount due
                                </dt>
                                <dd className="mt-1 text-4xl font-extrabold text-white">
                                    ₹{cart.totalPrice}
                                </dd>
                            </dl>

                            <ul
                                role="list"
                                className="text-sm font-medium divide-y divide-white divide-opacity-10"
                            >
                                {cart &&
                                    cart.cartItems.map((product) => (
                                        <li
                                            key={product.id}
                                            className="flex items-start py-6 space-x-4"
                                        >
                                            <img
                                                src={product.image}
                                                alt={product.name}
                                                className="flex-none w-28 h-28 rounded-md object-center object-contain bg-gray-50 "
                                            />
                                            <div className="flex-auto space-y-1">
                                                <h3 className="text-white">
                                                    {product.name}
                                                </h3>
                                                <p>{product.material}</p>
                                                <p>
                                                    {product.len} x{" "}
                                                    {product.width} x{" "}
                                                    {product.height}{" "}
                                                </p>
                                            </div>
                                            <p className="flex-none text-base font-medium text-white">
                                                {product.qty} x {product.price}=
                                                ₹{product.qty * product.price}
                                            </p>
                                        </li>
                                    ))}
                            </ul>

                            <dl className="text-md font-medium space-y-6 border-t border-white border-opacity-40 pt-6">
                                <div className="flex items-center justify-between">
                                    <dt>
                                        Subtotal (
                                        {cart.cartItems.reduce(
                                            (a, c) => a + c.qty,
                                            0,
                                        )}{" "}
                                        items)
                                    </dt>
                                    <dd>₹{cart.itemsPrice}</dd>
                                </div>

                                <div className="flex items-center justify-between">
                                    <dt>Shipping</dt>
                                    <dd>₹{cart.shippingPrice}</dd>
                                </div>

                                <div className="flex items-center justify-between">
                                    <dt>Taxes</dt>
                                    <dd>₹{cart.taxPrice}</dd>
                                </div>

                                <div className="flex items-center justify-between border-t border-white border-opacity-40 text-white pt-6">
                                    <dt className="text-lg">Total</dt>
                                    <dd className="text-lg">
                                        ₹{cart.totalPrice}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </section>

                    {/* Shipping Form */}
                    <section
                        aria-labelledby="payment-and-shipping-heading"
                        className="py-16 lg:max-w-2xl lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1"
                    >
                        <h2
                            id="payment-and-shipping-heading"
                            className="sr-only"
                        >
                            Payment and shipping details
                        </h2>

                        <form onSubmit={submitHandler} autoComplete="on">
                            <div className="hidden">
                                <h1>Payment</h1>
                            </div>
                            <div className="invisible">
                                <div>
                                    <label htmlFor="paymentMethod">
                                        Choose a Payment Method:{" "}
                                    </label>
                                    <select
                                        name="paymentMethod"
                                        id="paymentMethod"
                                        onChange={(e) =>
                                            setPaymentMethod(e.target.value)
                                        }
                                    >
                                        <option value="OnlinePayment">
                                            Online Payment
                                        </option>
                                        <option value="COD">
                                            Cash on Delivery
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
                                <div className="mt-10">
                                    <h1 className="addressTitle">Shipping</h1>
                                    <p className="addressInfo">
                                        Please enter your shipping details.
                                    </p>
                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                                        <div className="sm:col-span-3">
                                            <label
                                                htmlFor="fullname"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Full Name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    autoComplete="name"
                                                    type="text"
                                                    id="fullName"
                                                    placeholder="Enter full name"
                                                    value={fullName}
                                                    onChange={(e) =>
                                                        setFullName(
                                                            e.target.value,
                                                        )
                                                    }
                                                    minLength="4"
                                                    required
                                                    autoFocus
                                                    className="block w-full h-14 border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                                    aria-invalid="true"
                                                    aria-describedby="fullname-error"
                                                />
                                                {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <ExclamationCircleIcon
                                                        className="h-5 w-5 text-red-500"
                                                        aria-hidden="true"
                                                    />
                                                </div> */}
                                            </div>
                                            <p
                                                className="mt-2 text-sm text-red-600"
                                                id="fullname-error"
                                            >
                                                {fullName.length < 4 &&
                                                    "Enter the receivers full name"}
                                            </p>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label
                                                htmlFor="address"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Address
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    minLength="10"
                                                    placeholder="Enter address"
                                                    type="text"
                                                    id="address"
                                                    name="address"
                                                    autoComplete="street-address"
                                                    value={addressLine1}
                                                    onChange={(e) =>
                                                        setAddressLine1(
                                                            e.target.value,
                                                        )
                                                    }
                                                    required
                                                    className="block h-14  w-full border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                                    aria-invalid="true"
                                                    aria-describedby="address-error"
                                                />
                                                {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <ExclamationCircleIcon
                                                        className="h-5 w-5 text-red-500"
                                                        aria-hidden="true"
                                                    />
                                                </div> */}
                                            </div>
                                            <p
                                                className="mt-2 text-sm text-red-600"
                                                id="address-error"
                                            >
                                                {addressLine1.length < 10 &&
                                                    "Your address seems to short"}
                                            </p>
                                        </div>

                                        <div className="sm:col-span-3 -mt-3">
                                            {showAddressField2 ? null : (
                                                <button
                                                    className="sm:col-span-3 lock text-sm font-medium text-gray-700 "
                                                    onClick={ShowAddressField2}
                                                >
                                                    {!showAddressField2 &&
                                                        "+ Longer Address"}
                                                </button>
                                            )}
                                            {showAddressField2 && (
                                                <>
                                                    {" "}
                                                    <label
                                                        htmlFor="address2"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Address Line 2
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            minLength="10"
                                                            placeholder="Enter address"
                                                            type="text"
                                                            id="address2"
                                                            name="address2"
                                                            value={addressLine2}
                                                            onChange={(e) =>
                                                                setAddressLine2(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            className="block h-14  w-full border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                                        />
                                                    </div>{" "}
                                                </>
                                            )}
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="postal-code"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Postal code
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    id="postal-code"
                                                    name="postal-code"
                                                    autoComplete="postal-code"
                                                    pattern="[0-9]*"
                                                    maxLength="6"
                                                    placeholder="Enter postalCode"
                                                    className="relative h-14  block w-full border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                                    value={postalCode}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value ===
                                                                "" ||
                                                            /^[0-9\b]+$/.test(
                                                                e.target.value,
                                                            )
                                                        ) {
                                                            setPostalCode(
                                                                e.target.value,
                                                            );
                                                        }
                                                    }}
                                                    // onBlurCapture={
                                                    //     fetchCityStateFromPostCode
                                                    // }
                                                    required
                                                    aria-invalid={notReal}
                                                />
                                                {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <ExclamationCircleIcon
                                                        className="h-5 w-5 text-red-500"
                                                        aria-hidden={!notReal}
                                                    />
                                                </div> */}
                                                <p
                                                    className="mt-2 text-sm text-red-600"
                                                    id="postal-error "
                                                >
                                                    {notReal &&
                                                        "Not a real zip code."}
                                                </p>
                                            </div>
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="city"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                City
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    autoComplete="address-level2"
                                                    type="text"
                                                    id="city"
                                                    name="city"
                                                    className="block h-14  w-full border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                                    placeholder="Enter City"
                                                    value={city}
                                                    onChange={(e) =>
                                                        setCity(e.target.value)
                                                    }
                                                    required
                                                />
                                            </div>
                                            <p className="mt-2 text-sm text-red-600">
                                                {city.length < 2 &&
                                                    "Enter a city"}
                                            </p>
                                        </div>

                                        <div>
                                            <label
                                                htmlFor="state"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                State
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    autoComplete="address-level1"
                                                    type="text"
                                                    id="state"
                                                    name="state"
                                                    className="block h-14  w-full border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                                    placeholder="Enter State"
                                                    value={state}
                                                    onChange={(e) =>
                                                        setState(e.target.value)
                                                    }
                                                    required
                                                />
                                            </div>
                                            <p className="mt-2 text-sm text-red-600">
                                                {state.length < 2 &&
                                                    "Enter a state"}
                                            </p>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label
                                                htmlFor="landmark"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Landmark
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    minLength="10"
                                                    placeholder="Enter landmark"
                                                    type="text"
                                                    id="landmark"
                                                    name="landmark"
                                                    value={landmark}
                                                    onChange={(e) =>
                                                        setLandmark(
                                                            e.target.value,
                                                        )
                                                    }
                                                    className="block h-14  w-full border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label
                                                htmlFor="country"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Country
                                            </label>
                                            <select
                                                id="country"
                                                name="country"
                                                className="mt-1 h-14  block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm rounded-md"
                                                value={country}
                                                onChange={() =>
                                                    setCountry("India")
                                                }
                                                required
                                            >
                                                <option value="India">
                                                    India
                                                </option>
                                            </select>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label
                                                htmlFor="contact"
                                                className="block  text-sm font-medium text-gray-700"
                                            >
                                                Contact No.
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="tel"
                                                    autoComplete="tel-local"
                                                    id="contact"
                                                    placeholder="Enter Contact No."
                                                    value={contactNo}
                                                    onFocus={() =>
                                                        setonMobileEnter(true)
                                                    }
                                                    onBlur={() =>
                                                        setonMobileEnter(false)
                                                    }
                                                    onChange={(e) => {
                                                        setContactNo(
                                                            e.target.value,
                                                        );
                                                        validateMobileNumber();
                                                    }}
                                                    required
                                                    className="block h-14 w-full border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="mt-2 text-sm text-red-600">
                                                {onMobileEnter ? (
                                                    <div className="errTooltip">
                                                        {!(
                                                            contactNo.length ===
                                                            10
                                                        ) ? (
                                                            <p>
                                                                Enter the
                                                                Recipents
                                                                Contact Number
                                                            </p>
                                                        ) : (
                                                            <p className="successp">
                                                                <CheckMark className="errCheckmark " />
                                                                Click continue
                                                                to move on
                                                            </p>
                                                        )}

                                                        {/[+]/.test(
                                                            contactNo,
                                                        ) ? (
                                                            <p className="errp">
                                                                Enter the
                                                                Contact Number
                                                                without a
                                                                country code.
                                                            </p>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {mobileVaidateError && (
                                                            <p className="errp">
                                                                Enter a valid
                                                                mobile number
                                                            </p>
                                                        )}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {!onMobileEnter && (
                                                    <div className="pb-16"></div>
                                                )}
                                            </div>
                                            <div className="mt-10 flex justify-center pt-6 border-t border-gray-200">
                                                <Button
                                                    buttonName="Continue"
                                                    buttonType="submit"
                                                    buttonClass="Button"
                                                    loading={loadingPlaceOrder}
                                                    disabled={
                                                        cart.cartItems
                                                            .length === 0 ||
                                                        loadingPlaceOrder
                                                    }
                                                />
                                                {errorPlaceOrder && (
                                                    <MessageBox varient="danger">
                                                        {errorPlaceOrder}
                                                    </MessageBox>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    );
}

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import SlideOverCartComponent from "./SlideOverCartComponent";

export default function SlideOver({ open, setOpen }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-50 inset-0 overflow-hidden"
                open={open}
                onClose={setOpen}
            >
                <div className="absolute  inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-600"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-600"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-200 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-200 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="relative w-screen max-w-screen-sm">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-200"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-600"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"></div>
                                </Transition.Child>
                                <div className="h-full flex flex-col pb-6 bg-white shadow-xl overflow-y-scroll rounded-2xl">
                                    <div className="px-4 pt-4 sm:px-6 flex justify-between items-center sticky top-0 z-40 bg-white opacity-90 backdrop-filter  backdrop-blur-lg">
                                        <Dialog.Title className="text-2xl font-bold text-gray-900 ">
                                            Cart
                                        </Dialog.Title>
                                        <button
                                            className="rounded-md text-gray-300 hover:text-orange-450 focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">
                                                Close panel
                                            </span>
                                            <XIcon
                                                className="h-12 w-12"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="mt-6 w-full px-4 sm:px-6">
                                        <SlideOverCartComponent
                                            setOpen={setOpen}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

import React from 'react';

// import { Container } from './styles';

export default function MessageBox(props) {
  return (
    <div className={`alert alert-${props.varient || 'info'}`}>
        {props.children}
    </div>
  );
}

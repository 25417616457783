import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listProductCategories, listProducts } from "../actions/productActions";
import { listTopSellers } from "../actions/userActions";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import Mailchimp from "react-mailchimp-form";
import { Helmet } from "react-helmet";
import CrossFadeImage from "../components/UtilityComponents/CrossFadeImage";
import { EventTrackingHandler } from "../usePageViews";

export default function HomeScreen(props) {
    const dispatch = useDispatch();
    const sizeSectionRef = createRef(null);
    const productList = useSelector((state) => state.productList);
    const { loading, error, products } = productList;

    const productCategoryList = useSelector(
        (state) => state.productCategoryList,
    );
    const {
        loading: loadingCategories,
        error: errorCategories,
        categories,
    } = productCategoryList;

    const [categoryPage, setCategoryPage] = useState(0);
    const [categorySelected, setCategorySelected] = useState("all");
    const [productSelected, setProductSelected] = useState(null);
    const [loadedBanner, setLoadedBanner] = useState(false);
    const [loadedCategoryImage, setLoadedCategoryImage] = useState(false);
    const [tempCategoryImage, setTempCategoryImage] = useState("");

    useEffect(() => {
        dispatch(listTopSellers());
        dispatch(listProducts({}));
        dispatch(listProductCategories());
    }, [dispatch]);

    useEffect(() => {
        if (
            categories &&
            categoryPage > categories.length &&
            categoryPage < categories.length
        ) {
            setCategoryPage(0);
        }
    }, [categories, categoryPage]);

    useEffect(() => {
        dispatch(
            listProducts({
                category: categorySelected !== "all" ? categorySelected : "",
            }),
        );
    }, [categorySelected, dispatch]);

    smoothscroll.polyfill();
    const executeScroll = () => {
        sizeSectionRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    //mailchimp
    const url =
        "https://gmail.us10.list-manage.com/subscribe/post?u=eae80e8f6b67ccee8e0273a99&amp;id=f3c9d3acb0";

    const puzzle = [
        {
            id: "1",
            pieceImg: "/assets/images/puzzle/piece1.svg",
            img: "",
            title: "",
            description: "",
        },
        {
            id: "2",
            pieceImg: "/assets/images/puzzle/piece2.svg",
            img: "/assets/images/puzzle/envfriendly.svg",
            title: "Bio-Degradable",
            description:
                "Packaging is made out of natural materials making them easy on the enviorment",
        },
        {
            id: "3",
            pieceImg: "/assets/images/puzzle/piece3.svg",
            img: "",
            title: "",
            description: "",
        },
        {
            id: "fake",
            pieceImg: "",
            img: "",
            title: "",
            description: "",
        },
        {
            id: "4",
            pieceImg: "/assets/images/puzzle/piece4.svg",
            img: "/assets/images/puzzle/recyclepuzzle.svg",
            title: "",
            description:
                "Reducing your carbon footprint by using boxes that are made from recycled materials.",
        },
        {
            id: "5",
            pieceImg: "/assets/images/puzzle/piece5.svg",
            img: "/assets/images/puzzle/Sturdy.svg",
            title: "Sturdy",
            description:
                "Packaging that offers the rigidity of wood and weight savings of cardboard",
        },
        {
            id: "6",
            pieceImg: "/assets/images/puzzle/piece6.svg",
            img: "",
            title: "",
            description: "",
        },
        {
            id: "fake2",
            pieceImg: "",
            img: "",
            title: "",
            description: "",
        },
        {
            id: "7",
            pieceImg: "/assets/images/puzzle/piece7.svg",
            img: "",
            title: "",
            description: "",
        },
    ];

    return (
        <div className="page-animate ">
            <Helmet>
                <title>
                    {global.config.websiteName} - Your search for personalized
                    packaging ends here.
                </title>
                <meta
                    name="description"
                    content={
                        "Make ugly and generic packaging things of the past. In the future, there's only Indiekrate. Join the personalised packaging revolution. Click the link."
                    }
                />
                <link
                    rel="canonical"
                    href={`https://${global.config.websiteName}.com/`}
                ></link>
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    property="og:url"
                    content={`https://${global.config.websiteName}.com/`}
                />
                <meta
                    property="og:description"
                    content={
                        "Make ugly and generic packaging things of the past. In the future, there's only Indiekrate. Join the personalised packaging revolution. Click the link."
                    }
                />
            </Helmet>
            <div
                className="relative"
                style={{
                    minHeight: "100vh",
                    background: "#7FADCD",
                }}
            >
                <div
                    style={{
                        minHeight: "100vh",
                        background: "#7FADCD",
                    }}
                    className="h-full  md:mx-4 pt-16 bg-cover flex flex-wrap  gap-6   items-center justify-center"
                >
                    <div className="w-full grid place-items-center  md:w-1/2">
                        <img
                            style={
                                loadedBanner
                                    ? {
                                          width: "100%",
                                          maxHeight: "85rem",
                                          objectFit: "contain",
                                      }
                                    : { display: "none" }
                            }
                            onLoad={() => setLoadedBanner(true)}
                            src={
                                process.env.PUBLIC_URL +
                                "/assets/images/d66880dd61877deeb187e272ff228be22.png"
                            }
                            alt="box"
                            className="w-3/4 "
                        />
                    </div>
                    <div className="w-full md:w-1/2  md:mx-2 md:text-left text-center md:pr-4 max-w-lg  md:max-w-xl xl:max-w-5xl 2xl:max-w-6xl">
                        <h1 className="pl-4 leading-tight text-white font-extrabold text-5xl  xs:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl mx-auto border-8 rounded border-dashed  border-orange-500 max-w-4xl xl:max-w-full  py-4">
                            Create your own Wooden box
                        </h1>

                        <div className="pt-12 pb-8 px-4">
                            <h2 className="text-white text-2xl xs:text-xl md:text-2xl xl:text-3xl 2xl:text-4xl leading-tight max-w-6xl mt-9">
                                Create your own packaging/gift from <br /> a
                                selection of our wooden boxes
                            </h2>
                            <br />
                            <h2 className="text-white text-2xl xs:text-2xl md:text-2xl xl:text-3xl 2xl:text-4xl leading-tight max-w-6xl">
                                Add your own message or even upload <br /> an
                                image
                            </h2>
                            <br />
                            <div className=" sm:mt-8  flex md:flex-row flex-col justify-center md:justify-start gap-4">
                                <div className="rounded-md shadow">
                                    <Link
                                        to="/contact"
                                        className=" w-full flex items-center justify-center px-8 2xl:px-8  py-2 2xl:py-6 border border-transparent text-xl xl:text-2xl 2xl:text-2xl font-semibold rounded-2xl text-white bg-orange-450 hover:bg-red-600  md:text-lg md:px-6"
                                    >
                                        Contact Us
                                    </Link>
                                </div>
                                <div className="">
                                    <Link
                                        to="/search/name"
                                        className=" w-full flex items-center justify-center px-8 2xl:px-8 py-2 2xl:py-6 border border-transparent text-xl xl:text-2xl 2xl:text-2xl  font-semibold rounded-2xl text-orange-450 bg-white hover:bg-orange-200  md:text-lg md:px-6"
                                    >
                                        Our Store
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Feature Section */}

            <section
                className="py-20  "
                style={{ minHeight: "85vh", backgroundColor: "#F37575" }}
            >
                <div className=" mb-18 grid grid-cols-4 ">
                    <h1 className="homepageHeadings w-full">
                        How it <br /> Works
                    </h1>
                </div>
                <div className="flex flex-col items-center justify-center w-full ">
                    <div className="container ">
                        <div className="flex flex-wrap  justify-center">
                            <div className="w-full md:w-4/12  lg:mb-0 mb-12">
                                <div className="">
                                    <div
                                        className="max-w-full mx-auto flex items-end justify-center"
                                        style={{
                                            maxWidth: "200px",
                                            height: "160px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: "170px",
                                                height: "160px",
                                            }}
                                            className="flex items-center"
                                        >
                                            <img
                                                alt="Style & size"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/Style&size.svg"
                                                }
                                                className=" object-center object-contain "
                                            />
                                        </div>
                                    </div>
                                    <div className="pt-6 text-center">
                                        <h5 className="text-white font-semibold text-4xl">
                                            Style & size
                                        </h5>
                                        <p className="mt-1 font-medium text-base text-white h-32">
                                            Pick your box style and size <br />{" "}
                                            from the options below
                                        </p>
                                        <div className="mt-6 font-extrabold text-7xl text-white">
                                            1
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 lg:mb-0 mb-12  md:border-r-8 md:border-l-8 border-dashed border-white">
                                <div className="">
                                    <div
                                        className="  max-w-full mx-auto flex items-end justify-center"
                                        style={{
                                            maxWidth: "200px",
                                            height: "160px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: "125px",
                                                height: "160px",
                                            }}
                                            className="flex items-center"
                                        >
                                            <img
                                                alt="Customise the box with text "
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/customise.svg"
                                                }
                                                className=" object-center object-contain "
                                            />
                                        </div>
                                    </div>
                                    <div className="pt-6 text-center">
                                        <h5 className="text-white font-semibold text-4xl">
                                            Customise
                                        </h5>
                                        <p className="mt-1 font-medium text-base text-white h-32"></p>
                                        <div className="mt-6 font-extrabold text-7xl text-white">
                                            2
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12  lg:mb-0 mb-12">
                                <div className="">
                                    <div
                                        className="  max-w-full mx-auto flex items-end justify-center"
                                        style={{
                                            maxWidth: "200px",
                                            height: "160px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                maxWidth: "140px",
                                                height: "160px",
                                            }}
                                            className="flex items-center"
                                        >
                                            <img
                                                alt="Customise the box with text "
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/placeorder.svg"
                                                }
                                                className=" object-center object-contain "
                                            />
                                        </div>
                                    </div>
                                    <div className="pt-6 text-center">
                                        <h5 className="text-white font-semibold text-4xl">
                                            Order
                                        </h5>
                                        <p className="mt-1 font-medium text-base text-white h-32">
                                            {" "}
                                            Confirm the customisation, <br />{" "}
                                            enter your order details and <br />{" "}
                                            place your order
                                        </p>
                                        <div className="mt-6 font-extrabold text-7xl text-white">
                                            3
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* TestFeature Section End*/}
            {/* Start Pick a style of Box */}
            <section
                style={{ backgroundColor: "#F2A74F", minHeight: "90vh" }}
                className="relative pt-24 pb-32"
            >
                <div>
                    <h1 className="homepageHeadings  ">
                        Pick a <br /> Style
                    </h1>
                </div>
                <div className="flex justify-center  mb-28">
                    <p className="text-3xl xl:text-5xl font-semibold text-white">
                        Choose from our 3 different styles of boxes{" "}
                    </p>
                </div>

                <div className="flex justify-center mx-auto px-4">
                    {/* Carousel Body */}
                    {categories &&
                        categories.map(
                            (category, index) =>
                                index === categoryPage && (
                                    <div
                                        key={index}
                                        className="relative items-center flex flex-wrap"
                                    >
                                        <div className="items-center flex flex-wrap  lg:ml-64">
                                            <div className="relative w-full md:w-4/12 ml-auto mr-auto px-4">
                                                {/* {!loadedCategoryImage && (
                                                    // <div
                                                    //     style={{
                                                    //         width: "30rem",
                                                    //         minHeight: "100%",
                                                    //     }}
                                                    // />
                                                    <img
                                                        alt={category.name}
                                                        className="relative z-40 max-w-full rounded-lg "
                                                        src={tempCategoryImage}
                                                        style={{
                                                            animation:
                                                                "fadeOut 600ms ease-out forwards",
                                                        }}
                                                    />
                                                )}
                                                <img
                                                    alt={category.name}
                                                    className="relative z-40 max-w-full rounded-lg "
                                                    src={category.categoryImage}
                                                    onLoad={() => {
                                                        setLoadedCategoryImage(
                                                            true,
                                                        );
                                                        setTempCategoryImage(
                                                            category.categoryImage,
                                                        );
                                                    }}
                                                /> */}
                                                <div className=" h-96 md:h-auto">
                                                    <CrossFadeImage
                                                        src={
                                                            category.categoryImage
                                                        }
                                                        alt={category.name}
                                                        transitionDuration={0.4}
                                                        curve="ease-in-out"
                                                    />
                                                    {/* <div style={{width:"32rem", height:"32rem"}}  className=" hidden md:block md:absolute z-0  bg-black  rounded-full bottom-0 right-0 transform opacity-90" /> */}
                                                </div>
                                            </div>

                                            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                                                <div className="md:pr-12 ">
                                                    <h1
                                                        style={{
                                                            animation:
                                                                "fadeInRight 1000ms ease-out forwards",
                                                        }}
                                                        className="max-w-full font-medium text-6xl   mt-6  leading-none text-white   text-bold pb-8 border-b-8 border-dashed border-white"
                                                    >
                                                        {category.name}
                                                    </h1>
                                                    <p className="mt-8 text-lg leading-relaxed text-white">
                                                        We have three pre-built
                                                        types of boxes to help
                                                        you get started faster.
                                                        You can add text and
                                                        images and you're good
                                                        to go.
                                                    </p>
                                                    <ul className="list-none mt-6">
                                                        <li className="py-2">
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-450 bg-white mr-3">
                                                                        <i className="fas fa-fingerprint"></i>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <h4 className="text-white">
                                                                        Carefully
                                                                        crafted
                                                                        boxes
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="py-2">
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-orange-450 bg-white mr-3">
                                                                        <i className="fab fa-html5"></i>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <h4 className="text-white">
                                                                        Amazing
                                                                        personalisation
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li className="py-2 pt-28">
                                                            <div className="flex items-center justify-end">
                                                                <div>
                                                                    <button
                                                                        className="Button noselect flex items-center justify-center text-base font-medium  md:py-4 md:text-lg "
                                                                        style={{
                                                                            width: "18.3rem",
                                                                            height: "4.5rem",
                                                                            border: "4px solid black",
                                                                            color: "black",
                                                                            borderRadius:
                                                                                "1rem",
                                                                            backgroundColor:
                                                                                "white",
                                                                        }}
                                                                        onClick={() => {
                                                                            setCategorySelected(
                                                                                category._id,
                                                                            );
                                                                            setProductSelected(
                                                                                null,
                                                                            );

                                                                            executeScroll();
                                                                            EventTrackingHandler(
                                                                                "PICK_A_STYLE",
                                                                                "CONTINUE",
                                                                                category.name,
                                                                            );
                                                                        }}
                                                                    >
                                                                        Continue
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute z-50 inset-y-0 left-0 flex justify-center  items-center">
                                            <button
                                                onClick={() => {
                                                    setCategoryPage(
                                                        categoryPage - 1,
                                                    );
                                                    setLoadedCategoryImage(
                                                        false,
                                                    );
                                                    EventTrackingHandler(
                                                        "PICK_A_STYLE",
                                                        "PREVIOUS STYLE",
                                                        category.name,
                                                    );
                                                }}
                                                style={{
                                                    backgroundColor: "black",
                                                    height: "6rem",
                                                    width: "6rem",
                                                }}
                                                className={
                                                    categories &&
                                                    categoryPage - 1 < 0
                                                        ? "hidden"
                                                        : "Button noselect bg-black flex justify-center rounded-full shadow-md h-24 w-24 text-2xl text-white hover:text-red-50 focus:text-red-50 focus:outline-none focus:shadow-outline"
                                                }
                                            >
                                                <span
                                                    className="block"
                                                    style={{
                                                        transform:
                                                            "scale(-1.4)",
                                                    }}
                                                >
                                                    &#x279c;
                                                </span>
                                            </button>
                                        </div>
                                        <div className="absolute z-50 inset-y-0 right-0 flex justify-center items-center">
                                            <button
                                                onClick={() => {
                                                    setCategoryPage(
                                                        categoryPage + 1,
                                                    );
                                                    setLoadedCategoryImage(
                                                        false,
                                                    );
                                                    EventTrackingHandler(
                                                        "PICK_A_STYLE",
                                                        "NEXT STYLE",
                                                        category.name,
                                                    );
                                                }}
                                                style={{
                                                    backgroundColor: "black",
                                                    height: "6rem",
                                                    width: "6rem",
                                                }}
                                                className={
                                                    categories &&
                                                    categories.length ===
                                                        categoryPage + 1
                                                        ? "hidden"
                                                        : "Button noselect bg-black rounded-full flex justify-center items-center shadow-md h-24 w-24 text-2xl text-white hover:text-red-50 focus:text-red-50  focus:outline-none focus:shadow-outline"
                                                }
                                            >
                                                <span
                                                    className="block"
                                                    style={{
                                                        transform: "scale(1.4)",
                                                    }}
                                                >
                                                    &#x279c;
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                ),
                        )}
                </div>
            </section>
            {/* End Pick the style */}

            {/* Start Pick the Size */}
            <section
                style={{ backgroundColor: "#4A4F79", minHeight: "90vh" }}
                className=" pt-10 pb-40 px-4 "
                ref={sizeSectionRef}
            >
                <div>
                    <h1 className="homepageHeadings  ">
                        Pick a <br />
                        Size
                    </h1>
                </div>
                <div className="flex justify-center ">
                    <p className="text-4xl font-semibold text-white">
                        We offer three categories of sizes, each for its own
                        specific use.
                    </p>
                </div>
                <div className="flex justify-center mt-12 ">
                    <div className="grid grid-flow-row md:grid-cols-3  border-4 rounded-md border-white gap-20 px-2 md:px-20 py-20">
                        <div className="hidden md:block col-span-2">
                            <div
                                style={{
                                    minWidth: "100%",
                                    height: "40rem",
                                }}
                                className={`grid place-items-center b rounded ${
                                    productSelected && "bg-[#606B8A]"
                                }`}
                            >
                                {products &&
                                    products
                                        .filter((p) => {
                                            const product =
                                                p.slug === productSelected
                                                    ? p
                                                    : p.slug !==
                                                          productSelected &&
                                                      productSelected !== "" &&
                                                      null;
                                            return product;
                                        })
                                        .map((p) => (
                                            <img
                                                key={p._id}
                                                src={p.image}
                                                alt=""
                                                style={{
                                                    maxWidth: "80%",
                                                    height: "40rem",
                                                    animation:
                                                        "fadeIn 1600ms ease-in-out forwards",
                                                }}
                                                className=" object-center object-contain "
                                            />
                                        ))}

                                {!productSelected && (
                                    <h1
                                        style={{
                                            width: "100%",
                                            height: "40rem",
                                        }}
                                        className="flex justify-center items-center  w-max px-12 md:w-imagesize gap-4 text-white font-bold text-7xl leading-tight"
                                    >
                                        Select <br />A Size
                                        <br />
                                        <svg
                                            className="w-36 h-40 hidden md:block"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                                            />
                                        </svg>
                                    </h1>
                                )}
                            </div>
                        </div>

                        <div className="grid place-items-center col-span-1 ">
                            <div className="flex flex-col gap-10 ">
                                {products &&
                                    products.map((product) => (
                                        <button
                                            key={product._id}
                                            onClick={() => {
                                                setProductSelected(
                                                    product.slug,
                                                );
                                                EventTrackingHandler(
                                                    "PICK_A_SIZE",
                                                    `SIZE SELECTED ${product.specs.len}cm x
                                                ${product.specs.width}cm x
                                                ${product.specs.height}cm`,
                                                    product.name,
                                                );
                                            }}
                                            style={{
                                                width: "26rem",
                                                height: "6rem",
                                                border:
                                                    productSelected ===
                                                    product.slug
                                                        ? "4px solid  #FC642D"
                                                        : "4px solid white",

                                                color: "white",
                                                borderRadius: "1rem",
                                                backgroundColor: "transparent",
                                                fontSize: "1.85rem",
                                            }}
                                            className="Button noselect border-white border-4 rounded-xl  flex justify-center items-center text-white font-semibold text-lg hover:border-orange-450 focus:border-orange-450 "
                                        >
                                            {product.specs.len}cm x{" "}
                                            {product.specs.width}cm x
                                            {product.specs.height}cm
                                        </button>
                                    ))}
                            </div>
                            <div className="justify-items-end">
                                <button
                                    onClick={() => {
                                        EventTrackingHandler(
                                            "PICK_A_SIZE",
                                            "Lets Customise !",
                                            productSelected,
                                        );
                                        props.history.push(
                                            `/product/${productSelected}`,
                                        );
                                    }}
                                    style={{
                                        width: "27rem",

                                        marginTop: "40px",
                                        marginBottom: "50px",
                                        borderRadius: "0.75rem",
                                    }}
                                    disabled={
                                        productSelected === null ? true : false
                                    }
                                    className="Button noselect grid place-items-center "
                                >
                                    Lets Customise !
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Pick the size */}

            {/* Enviormental Impact */}
            <section className="">
                <div>
                    <div className="flex justify-center items-center">
                        <h1
                            style={{
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "3em",
                                lineHeight: " 104.1%",
                                color: "#000000",
                            }}
                            className=" md:mb-32 mt-32"
                        >
                            Why Use MDF
                        </h1>
                    </div>
                    {/* Desktop Enviorment */}
                    <div className="hidden md:grid place-items-center w-full">
                        <div className="flex flex-wrap  max-w-screen-xl justify-center items-center gap-14  mt-24 mb-40">
                            {puzzle.map((piece, index) => (
                                <div
                                    key={index}
                                    className=" bg-white  max-w-xl "
                                    style={{
                                        backgroundImage: `url(${
                                            process.env.PUBLIC_URL +
                                            piece.pieceImg
                                        })  `,
                                        backgroundSize: "100% 100%",
                                        width: "310px",
                                        height: "330px",
                                    }}
                                >
                                    <div className="flex justify-center px-10  mt-14 sm:p-6 ">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                piece.img
                                            }
                                            alt=""
                                            className="object-scale-down h-28"
                                        />
                                    </div>
                                    <div className="px-4 py-4 sm:px-6 text-center font-semibold text-xl">
                                        {piece.title}
                                    </div>
                                    <div className=" px-16 pb-10 pt-5 text-center font-medium text-md">
                                        {piece.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Mobile Enviorment */}
                    <div className="md:hidden grid place-items-center w-full overflow-hidden overflow-x-scroll">
                        <div className="flex flex-row  justify-center items-center gap-14  mt-24 mb-40  ">
                            {puzzle
                                .filter((p) => {
                                    const piece =
                                        p.description !== "" ? p : null;
                                    return piece;
                                })
                                .map((piece, index) => (
                                    <div
                                        key={index}
                                        className="  bg-white  min-h-full"
                                        style={{
                                            backgroundImage: `url(${
                                                process.env.PUBLIC_URL +
                                                piece.pieceImg
                                            })  `,
                                            backgroundSize: "100% 100%",
                                            width: "310px",
                                            height: "330px",
                                        }}
                                    >
                                        <div>
                                            <div className="flex justify-center px-10   mt-24 sm:p-6 ">
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        piece.img
                                                    }
                                                    alt=""
                                                    className="object-scale-down h-28"
                                                />
                                            </div>
                                            <div className="px-4 py-4 sm:px-6 text-center font-semibold text-xl">
                                                {piece.title}
                                            </div>
                                            <div className=" px-16 pb-10 pt-5 text-center font-medium text-md">
                                                {piece.description}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Join Us */}
            <div className="grid place-items-center p-2">
                <div
                    style={{
                        border: "9px dashed #30323E",
                        boxSizing: "border-box",
                    }}
                    className="flex flex-col md:flex-row justify-center items-center py-24 px-8 mb-60"
                >
                    <h1
                        style={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "6vh",
                            lineHeight: "104.1%",
                            color: "#FC642D",
                        }}
                        className="pb-4 text-center"
                    >
                        The IndieKrate <br /> Community
                    </h1>
                    <div className="md:ml-12">
                        <div className="flex justify-center items-center flex-col md:flex-row ">
                            <Mailchimp
                                action={url}
                                //Adding multiple fields:
                                fields={[
                                    {
                                        name: "EMAIL",
                                        placeholder: "Email",
                                        type: "email",
                                        required: true,
                                    },
                                ]}
                                // Change predetermined language
                                messages={{
                                    sending: "Sending...",
                                    success: "Thank you for subscribing!",
                                    error: "An unexpected internal error has occurred.",
                                    empty: "You must write an e-mail.",
                                    duplicate:
                                        "You have already subscribed. Thanks!",
                                    button: "Join Us",
                                }}
                                // Add a personalized class
                                className="mailchip_input grid grid-flow-cols md:grid-cols-2 place-items-center text-sm"
                            />
                        </div>
                        <div className="flex items-center mt-8">
                            <input
                                id="joinUs"
                                name="joinUs"
                                type="checkbox"
                                className="h-7 w-7 border-gray-300 rounded text-orange-450 focus:ring-orange-50 border-4 border-black"
                            />

                            <label
                                htmlFor="joinUs"
                                className="ml-3 text-sm text-gray-600"
                            >
                                I agree to Indiekrate making jokes about me
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Join Us */}

            <div className="bg-dotsImage h-20 lg:h-28 mb-5 bg-center bg-no-repeat  bg-cover"></div>
        </div>
    );
}

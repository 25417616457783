export const CUSTOMBOX_LIST_REQUEST = "CUSTOMBOX_LIST_REQUEST";
export const CUSTOMBOX_LIST_SUCCESS = "CUSTOMBOX_LIST_SUCCESS";
export const CUSTOMBOX_LIST_FAIL = "CUSTOMBOX_LIST_FAIL";

export const CUSTOMBOX_DETAILS_REQUEST = "CUSTOMBOX_DETAILS_REQUEST";
export const CUSTOMBOX_DETAILS_SUCCESS = "CUSTOMBOX_DETAILS_SUCCESS";
export const CUSTOMBOX_DETAILS_FAIL = "CUSTOMBOX_DETAILS_FAIL";

export const CUSTOMBOX_CREATE_REQUEST = "CUSTOMBOX_CREATE_REQUEST";
export const CUSTOMBOX_CREATE_SUCCESS = "CUSTOMBOX_CREATE_SUCCESS";
export const CUSTOMBOX_CREATE_FAIL = "CUSTOMBOX_CREATE_FAIL";
export const CUSTOMBOX_CREATE_RESET = "CUSTOMBOX_CREATE_RESET";

export const CUSTOMBOX_UPDATE_REQUEST = "CUSTOMBOX_UPDATE_REQUEST";
export const CUSTOMBOX_UPDATE_SUCCESS = "CUSTOMBOX_UPDATE_SUCCESS";
export const CUSTOMBOX_UPDATE_FAIL = "CUSTOMBOX_UPDATE_FAIL";
export const CUSTOMBOX_UPDATE_RESET = "CUSTOMBOX_UPDATE_RESET";

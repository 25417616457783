import axios from "axios";
import {
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_SIGNIN_FAIL,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_SUCCESS,
    USER_SIGNOUT,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_TOPSELLER_LIST_REQUEST,
    USER_TOPSELLER_LIST_SUCCESS,
    USER_TOPSELLER_LIST_FAIL,
    USER_FORGOT_REQUEST,
    USER_FORGOT_SUCCESS,
    USER_FORGOT_FAIL,
    USER_RESET_REQUEST,
    USER_RESET_SUCCESS,
    USER_RESET_FAIL,
} from "../constants/userConstants";

export const signin = (formData) => async (dispatch) => {
    const { email, password } = formData;
    dispatch({ type: USER_SIGNIN_REQUEST, payload: { email, password } });
    try {
        const { data } = await axios.post("/api/users/signin", {
            email,
            password,
        });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_SIGNIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const googleAuth = (result, token) => async (dispatch) => {
    dispatch({ type: USER_SIGNIN_REQUEST, payload: { result, token } });

    try {
        const { data } = await axios.post("/api/users/google-auth", {
            result,
            token,
        });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_SIGNIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const signup = (formData) => async (dispatch) => {
    const { name, email, password, mobileNumber } = formData;
    dispatch({
        type: USER_REGISTER_REQUEST,
        payload: { name, email, password },
    });
    try {
        const { data } = await axios.post("/api/users/register", {
            name,
            email,
            password,
            mobileNumber,
        });
        dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const signout = () => (dispatch) => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("shippingAddress");

    dispatch({ type: USER_SIGNOUT });
};

export const detailsUser = (userId) => async (dispatch, getState) => {
    dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await axios.get(`/api/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${userInfo?.token}`,
            },
        });
        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: USER_DETAILS_FAIL, payload: message });
    }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await axios.put(`/api/users/profile`, user, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        });
        dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
        dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
    }
};

export const updateUser = (user) => async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_REQUEST, payload: user });
    const {
        userSignin: { userInfo },
    } = getState();
    try {
        const { data } = await axios.put(`/api/users/${user._id}`, user, {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        });
        dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: USER_UPDATE_FAIL, payload: message });
    }
};

export const listUsers = () => async (dispatch, getState) => {
    dispatch({ type: USER_LIST_REQUEST });
    try {
        const {
            userSignin: { userInfo },
        } = getState();
        const { data } = await axios.get("/api/users", {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        });
        dispatch({ type: USER_LIST_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: USER_LIST_FAIL, payload: message });
    }
};

export const listTopSellers = () => async (dispatch) => {
    dispatch({ type: USER_TOPSELLER_LIST_REQUEST });
    try {
        const { data } = await axios.get("/api/users/top-sellers");
        dispatch({ type: USER_TOPSELLER_LIST_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
        dispatch({ type: USER_TOPSELLER_LIST_FAIL, payload: message });
    }
};

export const forgotPassword = (formData) => async (dispatch) => {
    const { email } = formData;
    dispatch({ type: USER_FORGOT_REQUEST, payload: { email } });
    try {
        const { data } = await axios.post("/api/users/forgot", { email });
        dispatch({ type: USER_FORGOT_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.error
                ? error.response.data.error
                : error.message;
        dispatch({
            type: USER_FORGOT_FAIL,
            payload: message,
        });
    }
};

export const resetPassword = (formData, token) => async (dispatch) => {
    const { password } = formData;
    dispatch({ type: USER_RESET_REQUEST, payload: { password } });
    try {
        const { data } = await axios.post(`/api/users/reset/${token}`, {
            password,
        });
        dispatch({ type: USER_RESET_SUCCESS, payload: data });
    } catch (error) {
        const message =
            error.response && error.response.data.error
                ? error.response.data.error
                : error.message;
        dispatch({
            type: USER_RESET_FAIL,
            payload: message,
        });
    }
};

import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import {
    addressCreateReducer,
    addressDeleteReducer,
    addressesListReducer,
    addressListReducer,
    addressUpdateReducer,
} from "./reducers/addressReducers";
import {
    brandDetailsReducer,
    brandListReducer,
} from "./reducers/brandReducers";
import { cartReducer } from "./reducers/cartReducers";
import {
    categoryDetailsReducer,
    categoryListReducer,
} from "./reducers/categoryReducers";
import { contactRegisterReducer } from "./reducers/contactReducers";
import {
    customBoxCreateReducer,
    customBoxDetailsReducer,
    customBoxListReducer,
    customBoxUpdateReducer,
} from "./reducers/customReducers";
import {
    orderCreateReducer,
    orderDetailsReducer,
    orderListReducer,
    orderMineListReducer,
    orderPayReducer,
    orderSendDataReducer,
} from "./reducers/orderReducers";
import {
    productCategoryFilteredListReducer,
    productCategoryListReducer,
    productDetailsReducer,
    productListReducer,
    productReviewCreateReducer,
    productReviewDeleteReducer,
} from "./reducers/productReducers";
import {
    imageDeleteReducer,
    imageUploadReducer,
} from "./reducers/uploadReducers";
import {
    userDetailsReducer,
    userListReducer,
    userRegisterReducer,
    userSigninReducer,
    userTopSellerListReducer,
    userUpdateProfileReducer,
    userUpdateReducer,
    passwordForgotReducer,
    passwordResetReducer,
} from "./reducers/userReducers";

const initalstate = {
    userSignin: {
        userInfo: localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo"))
            : null,
    },
    cart: {
        cartItems: localStorage.getItem("cartItems")
            ? JSON.parse(localStorage.getItem("cartItems"))
            : [],
        shippingAddress: localStorage.getItem("shippingAddress")
            ? JSON.parse(localStorage.getItem("shippingAddress"))
            : {},
        paymentMethod: "OnlinePayment",
    },
};
const reducer = combineReducers({
    productList: productListReducer,
    productDetails: productDetailsReducer,
    cart: cartReducer,
    contactRegister: contactRegisterReducer,
    userSignin: userSigninReducer,
    userRegister: userRegisterReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderMineList: orderMineListReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userUpdate: userUpdateReducer,
    orderList: orderListReducer,
    addressesList: addressesListReducer,
    addressList: addressListReducer,
    addressCreate: addressCreateReducer,
    addressUpdate: addressUpdateReducer,
    addressDelete: addressDeleteReducer,
    customBoxCreate: customBoxCreateReducer,
    customBoxDetails: customBoxDetailsReducer,
    customBoxUpdate: customBoxUpdateReducer,
    customBoxList: customBoxListReducer,
    orderSendData: orderSendDataReducer,
    userList: userListReducer,
    userTopSellerList: userTopSellerListReducer,
    productCategoryList: productCategoryListReducer,
    productReviewCreate: productReviewCreateReducer,
    productReviewDelete: productReviewDeleteReducer,
    categoryList: categoryListReducer,
    categoryDetails: categoryDetailsReducer,
    brandList: brandListReducer,
    brandDetails: brandDetailsReducer,
    productCategoryFilteredList: productCategoryFilteredListReducer,
    imageUpload: imageUploadReducer,
    imageDelete: imageDeleteReducer,
    passwordForgot: passwordForgotReducer,
    passwordReset: passwordResetReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    initalstate,
    composeEnhancer(applyMiddleware(thunk)),
);

export default store;

import React, { createRef, useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Link } from "react-router-dom";
import useDebounce from "./UtilityComponents/useDebounce";
import useComponentVisible from "./UtilityComponents/useComponentVisible";
import clsx from "clsx";

export const KEY_CODE_UP = 38;
export const KEY_CODE_DOWN = 40;

export default function SearchBox(props) {
    const { setSearchActive, searchActive, setIsOpen, isOpen } = props;
    const [value, setValue] = useState("");
    const [query, setQuery] = useState("");
    const [selectedIdx, setSelectedIdx] = useState(-1);
    const [items, setItems] = useState([]);
    const debouncedValue = useDebounce(query, 500);
    const { ref, isComponentVisible, setIsComponentVisible, appearAnimation } =
        useComponentVisible(false);

    useEffect(() => {
        const filterSearch = async () => {
            if (debouncedValue) {
                await axios
                    .post(`/api/products/name/${debouncedValue}`)
                    .then((response) => {
                        return response;
                    })
                    .then((res) => {
                        console.log(res.data);
                        setItems([...res.data]);
                    });
            } else if (debouncedValue.length === 0) {
                setItems([]);
            }
        };
        filterSearch();
    }, [debouncedValue]);

    const submitHandler = (e) => {
        e.preventDefault();
        props.history.push(`/search/name/${query.toLowerCase()}`);
        setSearchActive(false);
    };

    const onChange = (value) => {
        setValue(value);
        setQuery(value);
        setSelectedIdx(-1);
    };

    const onKeyDown = (evt) => {
        if (evt.keyCode === KEY_CODE_DOWN || evt.keyCode === KEY_CODE_UP) {
            const selectedIdxMax = items.length - 1;

            let selectedIdx1 = selectedIdx;
            evt.keyCode === KEY_CODE_DOWN
                ? (selectedIdx1 = selectedIdx1 + 1)
                : (selectedIdx1 = selectedIdx1 - 1);

            if (selectedIdx1 > selectedIdxMax) {
                selectedIdx1 = -1;
            } else if (selectedIdx1 < -1) {
                selectedIdx1 = selectedIdxMax;
            }

            let value;
            if (selectedIdx1 > -1) {
                value = items[selectedIdx1].name;
                console.log(value);
            } else {
                value = query;
                console.log(value);
            }

            if (selectedIdx1 !== selectedIdx) {
                setSelectedIdx(selectedIdx1);
                setValue(value);
            }
        }
    };

    const SearchSuggestions = () => {
        return (
            <div className={` z-10  w-full bg-white `}>
                <div className="h-px w-full bg-gray-300 "></div>
                <div className="screen-reader-only">
                    Options available, use up and down arrow keys to navigate
                </div>
                {items &&
                    items.map((p, i) => (
                        <Link
                            key={p._id}
                            to={`/product/${p.slug}`}
                            onClick={() => setSearchActive(false)}
                        >
                            <div
                                className={` pl-5 hover:bg-gray-200 rounded-2xl p-4`}
                                style={{
                                    fontWeight:
                                        selectedIdx === i ? "600" : "inherit",
                                    backgroundColor:
                                        selectedIdx === i &&
                                        "rgba(229, 231, 235)",
                                }}
                            >
                                <p>{p.name}</p>
                                <p className="text-sm">
                                    Dimensions: {p.specs.len} x {p.specs.width}{" "}
                                    x {p.specs.height}{" "}
                                </p>
                            </div>
                        </Link>
                    ))}
                <ul>
                    <li className="pl-5 p-2 text-gray-500 font-semibold text-sm">
                        Suggestions
                    </li>
                    <li className="pl-5 p-2 hover:bg-gray-200">
                        <Link
                            onClick={() => setSearchActive(false)}
                            to="/search/category/616a90575fec2308d0079b35/name/all/min/0/max/0/rating/0/order/newest/pageNumber/1/pageSize/20"
                        >
                            Different types of Hinged Boxes with Logos
                        </Link>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <>
            <Transition appear show={searchActive} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 overflow-y-auto"
                    style={{ zIndex: "50" }}
                    onClose={() => setSearchActive(false)}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-7xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="flex items-center text-lg font-medium leading-6 text-gray-900 border-b border-gray-200 pb-8"
                                >
                                    <form
                                        className="search w-full"
                                        onSubmit={submitHandler}
                                    >
                                        <div className="row flex flex-wrap  items-center w-full">
                                            <div
                                                onClick={() =>
                                                    setIsComponentVisible(true)
                                                }
                                                className="  flex-grow  rounded-l-3xl bg-white  focus:outline-none focus:ring focus:border-yellow-500 focus:shadow-2xl"
                                            >
                                                <div className=" inline-block relative w-full">
                                                    <input
                                                        ref={ref}
                                                        className={clsx(
                                                            "rounded-2xl h-14 p-1 pl-5 w-full  focus:ring-white text-base",
                                                            {
                                                                rounded:
                                                                    isComponentVisible ===
                                                                    true,
                                                            },
                                                        )}
                                                        type="text"
                                                        name="q"
                                                        id="q"
                                                        value={value}
                                                        placeholder="Searching for something..."
                                                        onKeyPress={(e) =>
                                                            onChange(
                                                                e.target.value,
                                                            )
                                                        }
                                                        onKeyDown={(evt) =>
                                                            onKeyDown(evt)
                                                        }
                                                        onChange={(evt) =>
                                                            onChange(
                                                                evt.target
                                                                    .value,
                                                            )
                                                        }
                                                        autoComplete="off"
                                                    ></input>
                                                </div>
                                            </div>

                                            <button
                                                className="br-0 rounded-3xl bg-orange-500  px-12 h-14 focus:outline-none focus:ring-2 shadow-xl focus:border-yellow-500 active:drop-shadow-2xl hover:bg-red-500"
                                                type="button"
                                                onClick={() => {
                                                    setSearchActive(false);
                                                    props.history.push(
                                                        `/search/name/${value}`,
                                                    );
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-8 w-8	text-white"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Title>
                                <div className="mt-2 overflow-y-scroll max-h-96 ">
                                    <SearchSuggestions />
                                </div>

                                {/* <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={() => setSearchActive(false)}
                                    >
                                        Got it, thanks!
                                    </button>
                                </div> */}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

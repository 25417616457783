import {
  CUSTOMBOX_CREATE_FAIL,
  CUSTOMBOX_CREATE_REQUEST,
  CUSTOMBOX_CREATE_RESET,
  CUSTOMBOX_CREATE_SUCCESS,
  CUSTOMBOX_DETAILS_FAIL,
  CUSTOMBOX_DETAILS_REQUEST,
  CUSTOMBOX_DETAILS_SUCCESS,
  CUSTOMBOX_LIST_FAIL,
  CUSTOMBOX_LIST_REQUEST,
  CUSTOMBOX_LIST_SUCCESS,
  CUSTOMBOX_UPDATE_FAIL,
  CUSTOMBOX_UPDATE_REQUEST,
  CUSTOMBOX_UPDATE_RESET,
  CUSTOMBOX_UPDATE_SUCCESS,
} from "../constants/customConstants";

export const customBoxListReducer = (
  state = { loading: true, products: [] },
  action
) => {
  switch (action.type) {
    case CUSTOMBOX_LIST_REQUEST:
      return { loading: true };
    case CUSTOMBOX_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case CUSTOMBOX_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customBoxDetailsReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case CUSTOMBOX_DETAILS_REQUEST:
      return { loading: true };
    case CUSTOMBOX_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case CUSTOMBOX_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customBoxCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMBOX_CREATE_REQUEST:
      return { loading: true };
    case CUSTOMBOX_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case CUSTOMBOX_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMBOX_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const customBoxUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMBOX_UPDATE_REQUEST:
      return { loading: true };
    case CUSTOMBOX_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case CUSTOMBOX_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMBOX_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

import {
    UPLOAD_CREATE_FAIL,
    UPLOAD_CREATE_REQUEST,
    UPLOAD_CREATE_RESET,
    UPLOAD_CREATE_SUCCESS,
    UPLOAD_DELETE_FAIL,
    UPLOAD_DELETE_REQUEST,
    UPLOAD_DELETE_RESET,
    UPLOAD_DELETE_SUCCESS,
} from "../constants/uploadConstants";

export const imageUploadReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_CREATE_REQUEST:
            return { loading: true, request: true };
        case UPLOAD_CREATE_SUCCESS:
            return {
                loading: false,
                request: false,
                success: true,
                image: action.payload,
            };
        case UPLOAD_CREATE_FAIL:
            return { loading: false, request: false, error: action.payload };
        case UPLOAD_CREATE_RESET:
            return {};
        default:
            return {};
    }
};

export const imageDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_DELETE_REQUEST:
            return { loading: true };
        case UPLOAD_DELETE_SUCCESS:
            return { loading: false, success: true };
        case UPLOAD_DELETE_FAIL:
            return { loading: false, error: action.payload };
        case UPLOAD_DELETE_RESET:
            return {};
        default:
            return state;
    }
};

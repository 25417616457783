import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
        useState(initialIsVisible);

    const [appearAnimation, setAppearAnimation] = useState(false);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };
    const handleClickInside = (event) => {
        if (ref.current && ref.current.contains(event.target)) {
            setAppearAnimation(true);
        }
    };
    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setIsComponentVisible(false);
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", handleHideDropdown, true);
        document.addEventListener("click", handleClickOutside, true);
        document.addEventListener("click", handleClickInside, true);
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickOutside, true);
            document.removeEventListener("click", handleClickInside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible, appearAnimation };
}

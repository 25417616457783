import React from "react";
import LoadingBox from "./LoadingBox";

export default function Button(props) {
    const {
        loading,
        buttonName,
        buttonType,
        buttonClass,
        onClickHandler,
        disabled,
    } = props;
    return (
        <button
            className={buttonClass}
            style={{
                minHeight: "4.7rem",
                display: "grid",
                placeItems: "center",
            }}
            type={buttonType}
            onClick={onClickHandler ? onClickHandler : null}
            disabled={disabled ? disabled : false}
        >
            {loading ? <LoadingBox loadingType="2"></LoadingBox> : buttonName}
        </button>
    );
}

import React, { lazy, Suspense } from "react";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import PrivateRoute from "./components/privateRoute";
import LoadingBox from "./components/LoadingBox";
import { Helmet } from "react-helmet";
import Notifications from "./components/Notifications";
import { Route } from "react-router";

const AboutScreen = lazy(() => import("./screens/AboutScreen"));
const AccountSettingsScreen = lazy(() =>
    import("./screens/AccountSettingsScreen"),
);
const ContactScreen = lazy(() => import("./screens/ContactScreen"));
const BottomSheetComponent = lazy(() =>
    import("./components/BottomSheetComponent"),
);
const SearchScreen = lazy(() => import("./screens/SearchScreen"));
const CartScreen = lazy(() => import("./screens/CartScreen"));
const FAQScreen = lazy(() => import("./screens/FAQScreen"));
const RulesScreen = lazy(() => import("./screens/Rules/RulesScreen"));
const PrivacyScreen = lazy(() => import("./screens/Rules/PrivacyScreen"));
const TermsOfUseScreen = lazy(() => import("./screens/Rules/TermsOfUseScreen"));
const ReturnsRefundsScreen = lazy(() =>
    import("./screens/Rules/ReturnsRefundsScreen"),
);
const ProfileScreen = lazy(() => import("./screens/ProfileScreen"));
const OrderHistoryScreen = lazy(() => import("./screens/OrderHistoryScreen"));
const SellerScreen = lazy(() => import("./screens/SellerScreen"));
const Example = lazy(() => import("./screens/Example"));

export const DefaultContainer = () => {
    return (
        <div id="page-container">
            <Helmet>
                <title>Indiekrate</title>
                <meta
                    name="description"
                    content="At Indiekrate we intend to connect creators from across the world with the tools and an platform to create personalised packaging"
                />
            </Helmet>
            <div id="content-wrap">
                <Navbar />
                <Suspense fallback={null}>
                    <BottomSheetComponent />
                </Suspense>
                <Notifications />
                <main className="flex-grow">
                    <div className="max-w-full mx-0 py-0 sm:px-0 lg:px-0">
                        <Route
                            exact
                            path="/product/:id"
                            component={ProductScreen}
                        />

                        <Suspense
                            fallback={
                                <>
                                    <LoadingBox loadingType="1" />
                                </>
                            }
                        >
                            <Route
                                exact
                                path="/test"
                                component={Example}
                            ></Route>
                            <Route path="/cart/:id?" component={CartScreen} />
                            <Route
                                path="/search"
                                component={SearchScreen}
                                exact
                            ></Route>

                            <Route
                                path="/search/name/:name?"
                                component={SearchScreen}
                                exact
                            ></Route>
                            <Route
                                path="/search/category/:category"
                                component={SearchScreen}
                                exact
                            ></Route>
                            <Route
                                path="/search/category/:category/name/:name"
                                component={SearchScreen}
                                exact
                            ></Route>
                            <Route
                                path="/search/category/:category/name/:name/min/:min/max/:max/rating/:rating/order/:order/pageNumber/:pageNumber/pageSize/:pageSize"
                                component={SearchScreen}
                                exact
                            ></Route>
                            <Route
                                path="/orderhistory"
                                component={OrderHistoryScreen}
                            ></Route>
                            <Route
                                path="/seller/:id"
                                component={SellerScreen}
                            ></Route>
                            <PrivateRoute
                                path="/profile"
                                component={ProfileScreen}
                            ></PrivateRoute>
                            <PrivateRoute
                                path="/account"
                                component={AccountSettingsScreen}
                            ></PrivateRoute>

                            <Route
                                exact
                                path="/about"
                                component={AboutScreen}
                            />
                            <Route
                                exact
                                path="/contact"
                                component={ContactScreen}
                            />
                            <Route
                                exact
                                path="/rules"
                                component={RulesScreen}
                            />
                            <Route exact path="/faq" component={FAQScreen} />
                            <Route
                                exact
                                path="/rules/privacy"
                                component={PrivacyScreen}
                            />
                            <Route
                                exact
                                path="/rules/terms-of-use"
                                component={TermsOfUseScreen}
                            />
                            <Route
                                exact
                                path="/rules/shipping-and-returns"
                                component={ReturnsRefundsScreen}
                            />
                        </Suspense>

                        <Route exact path="/" component={HomeScreen}></Route>
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    );
};

import {
    CONTACT_REGISTER_RESET,
    CONTACT_REGISTER_FAIL,
    CONTACT_REGISTER_REQUEST,
    CONTACT_REGISTER_SUCCESS,
} from "../constants/contactConstants";

export const contactRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_REGISTER_REQUEST:
            return { loading: true };
        case CONTACT_REGISTER_SUCCESS:
            return { loading: false, contact: action.payload, success: true };
        case CONTACT_REGISTER_FAIL:
            return { loading: false, error: action.payload };
        case CONTACT_REGISTER_RESET:
            return {};
        default:
            return state;
    }
};

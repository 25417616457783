import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router";

export default function usePageViews() {
    const location = useLocation();

    // Fired on every route change
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
}

export const EventTrackingHandler = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_EMPTY,
    CART_QTY_UPDATE_ITEM,
    CART_ADD_ITEM_SUCCESS,
    CART_ADD_ITEM_SUCCESS_RESET,
} from "../constants/cartConstants";

export const cartReducer = (state = { cartItems: [] }, action) => {
    switch (action.type) {
        // case CART_ADD_ITEM:
        //   const item = action.payload;
        //   const existItem = state.cartItems.find((x) => x.product === item.product);
        //   if (existItem) {
        //     return {
        //       ...state,
        //       error: '',
        //       cartItems: state.cartItems.map((x) =>
        //         x.product === existItem.product ? item : x,
        //       ),
        //     };
        //   } else {
        //     //concat cart items at the end of items
        //     return {
        //       ...state,
        //       error: '',
        //       cartItems: [...state.cartItems, item],
        //     };
        //   }
        case CART_ADD_ITEM:
            const item = action.payload;

            //concat cart items at the end of items
            return {
                ...state,
                error: "",
                cartItems: [...state.cartItems, item],
            };

        case CART_ADD_ITEM_SUCCESS:
            return {
                ...state,
                success: true,
            };
        case CART_ADD_ITEM_SUCCESS_RESET:
            return {
                ...state,
                success: false,
            };

        case CART_REMOVE_ITEM:
            return {
                ...state,
                error: "",
                cartItems: state.cartItems.filter(
                    (x) => x.id !== action.payload,
                ),
            };
        case CART_SAVE_SHIPPING_ADDRESS:
            return { ...state, shippingAddress: action.payload };
        case CART_SAVE_PAYMENT_METHOD:
            return { ...state, paymentMethod: action.payload };
        case CART_EMPTY:
            return { ...state, cartItems: [] };

        // case CART_QTY_UPDATE_ITEM:
        //     const updateItem = action.payload;
        //     const existItem = state.cartItems.find(
        //         (x) => x.id === updateItem.id,
        //     );

        //     if (existItem) {
        //         return {
        //             ...state,
        //             cartItems: state.cartItems.map((x) =>
        //                 x.id === existItem.id ? updateItem : x,
        //             ),
        //         };
        //     }
        //     return state;

        default:
            return state;
        // case CART_ADD_CUSTOMBOX:
        //     const customitem = action.payload;
        //     const existcustomItem = state.cartItems.find(
        //         (x) => x.product === customitem.product
        //     );
        // if (existcustomItem) {
        //     return {
        //         ...state,
        //         cartItems: state.cartItems.map((x) =>
        //             x.product === existcustomItem.product ? customitem : x
        //         ),
        //     };
        // } else {
        //         //concat cart items at the end of items
        //         return {
        //             ...state,
        //             cartItems: [...state.cartItems, customitem],
        //         };
        //     }
    }
};

import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { detailsProduct } from "../actions/productActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import Rating from "../components/Rating";
import { Disclosure } from "@headlessui/react";
import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline";
import PageNotFound from "../screens/NotFoundScreen";
import "../styles/productScreen.css";
import { addToCart } from "../actions/cartActions";
import QuantitySelector from "../components/QuantitySelector";
import { PRODUCT_DETAILS_RESET } from "../constants/productConstants";
import Tooltip from "../components/TooltipComponent/Tooltip";
import { toSvg } from "html-to-image";
import axios from "axios";
import { Transition } from "@headlessui/react";
import { EventTrackingHandler } from "../usePageViews";

const PersonalizeBoxV4 = lazy(() =>
    import("../components/ProductComponents/PersonalizeBoxV4"),
);
const RelatedProductsComponent = lazy(() =>
    import("../components/ProductComponents/RelatedProductsComponent"),
);
const ProductReviews = lazy(() =>
    import("../components/ProductComponents/ProductReviews"),
);

const details = [
    {
        name: "Features",
        items: [
            "Multiple design configurations",
            "Interior dividers",
            "Water-resistant",
            "Durable",
        ],
    },
    {
        name: "Shipping",
        items: ["Shipping within 48 Hours", "Fast Shipping can be requested"],
    },
    {
        name: "Care",
        items: [
            "Long exposure to mosture may case damage to MDF",
            "Clean with a dry cloth",
        ],
    },
    {
        name: "Payment",
        items: [
            "We support a growing list of payment providers",
            "Payment Options: UPI, Credit/Debit cards and leading digital wallets",
        ],
    },
];

export default function ProductScreen(props) {
    const dispatch = useDispatch();
    const productId = props.match.params.id;
    const [qty, setQty] = useState(1);
    const [loadingCart, setLoadingCart] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [buttonSelected, setButtonSelected] = useState(1);
    const [showHideMoreQuatitySelector, setShowHideMoreQuatitySelector] =
        useState(false);
    const [personalised, setPersonalised] = useState("none");

    const productDetails = useSelector((state) => state.productDetails);
    const { loading, error, product } = productDetails;
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;

    const [preview, setPreview] = useState();

    const commentRef = useRef(null);

    useEffect(() => {
        if (!product || product.slug !== productId) {
            dispatch({ type: PRODUCT_DETAILS_RESET });
            dispatch(detailsProduct(productId));
        }
        // console.log("product details useEffect running");
    }, [dispatch, product, productId]);

    const addToCartHandler = async (e, redirectToCart) => {
        e.preventDefault();
        setLoadingCart(true);
        EventTrackingHandler("PRODUCT_PAGE", "ADD_TO_CART", product.name);

        try {
            if (product.personalisation) {
                toSvg(refHTML.current).then(async (dataUrl) => {
                    const svg = decodeURIComponent(dataUrl);
                    const blob = new Blob([svg], {
                        type: "image/svg",
                    }).slice(33);

                    const fileName = `indiekrate-user-upload-product-${productId}-date-${Date.now()}.svg`;
                    const svgURL = `https://indiekratebucket.s3.ap-southeast-1.amazonaws.com/${fileName}`;
                    const file = new File([blob], fileName, {
                        lastModified: new Date(),
                        type: "image/svg",
                    });

                    //upload

                    const bodyFormData = new FormData();
                    bodyFormData.append("image", file);

                    const imageAdded = preview ? true : false;

                    await axios
                        .post("/api/uploads/s3/svg", bodyFormData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((data) => {
                            // console.log(data);
                            dispatch(
                                addToCart(
                                    productId,
                                    qty,
                                    personalised,
                                    data.data,
                                    imageAdded,
                                ),
                            );
                            setLoadingCart(false);
                        })
                        .catch((error) => {
                            dispatch(
                                addToCart(
                                    productId,
                                    qty,
                                    personalised,
                                    error,
                                    false,
                                ),
                            );
                            setLoadingCart(false);
                            // console.log("Could not get image: ", error);
                        });
                    // setTimeout(() => {
                    //     const text = "faffafsaasf";
                    //     dispatch(addToCart(productId, qty, personalised, text));
                    //     setLoadingCart(false);
                    // }, 1000);
                });
            } else {
                setTimeout(() => {
                    const text = "No Image Personalisation";
                    dispatch(
                        addToCart(productId, qty, personalised, text, false),
                    );
                    setLoadingCart(false);
                }, 1000);
            }
        } catch (error) {
            // console.log(error);
            setLoadingCart(false);
        }

        // console.log("add to cart Handler ran");
        if (redirectToCart) {
            props.history.push("/cart");
        }
    };
    const refHTML = useRef();

    const changeImage = (image) => {
        setSelectedImage(image);
    };

    const selectedWrapperFunc = (qty, buttonNo) => {
        setQty(qty);
        setButtonSelected(buttonNo);
    };

    const executeScroll = () => commentRef.current.scrollIntoView();

    const showHideMoreQuatitySelectorHandler = () =>
        setShowHideMoreQuatitySelector(!showHideMoreQuatitySelector);

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    return (
        <div>
            {loading ? (
                <LoadingBox loadingType="1"></LoadingBox>
            ) : error ? (
                <div>
                    <MessageBox varient="danger">{error}</MessageBox>
                    <PageNotFound></PageNotFound>
                </div>
            ) : (
                <div className="page-animate">
                    <Helmet encodeSpecialCharacters={false}>
                        <script type="application/ld+json">
                            {`
                                {
                                    "@context": "http://schema.org",
                                    "@type": "Product",
                                    "name": "${product.name}",
                                    "description": "${product.description}",
                                    "image": [
                                        "${product.image}"
                                    ],
                                    "brand": {
                                        "@type": "Brand",
                                        "name": "${product.seller.seller.name}"
                                    },
                                    
                                    "aggregateRating": {
                                        "@type": "AggregateRating",
                                        "ratingValue": "${product.rating}",
                                        "reviewCount": "${product.numReviews}"
                                    },
                                    "offers": {
                                        "@type": "Offer",
                                        "url": "https://${global.config.websiteName}.com/${product.slug}",
                                        "priceCurrency": "INR",
                                        "price": "${product.price}",
                                        "itemCondition": "https://schema.org/NewCondition",
                                        "availability": "https://schema.org/InStock",
                                        "eligibleRegion": ["IN"]
                                    }
                                }
                            `}
                        </script>

                        <title>{product.name} - Indiekrate</title>
                        <meta
                            name="description"
                            content={product.description.slice(0, 100)}
                        />
                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                        <meta
                            property="og:url"
                            content={`https://${global.config.websiteName}.com/${product.slug}`}
                        />
                        <meta property="og:type" content="og:product" />
                        <meta property="og:title" content={product.title} />
                        <meta
                            property="og:description"
                            content={product.description}
                        />
                        <meta property="og:image" content={product.image} />
                    </Helmet>
                    <div>
                        <div className="flex flex-col md:flex-row  md:mr-2">
                            {/* Mobile Images */}
                            <section className=" md:hidden ">
                                <div className="mt-4 flow-root ">
                                    <div className=" overflow-hidden ">
                                        <div
                                            style={{
                                                minHeight: "61vh",
                                                overflowY: "hidden",
                                            }}
                                            className="box-content py-2 relative overflow-x-scroll overflow-y-none"
                                        >
                                            <div className="absolute min-w-full min-h-full px-4 flex space-x-8 sm:px-6 ">
                                                {[
                                                    product.image,
                                                    ...product.images,
                                                ].map((x) => (
                                                    <div
                                                        key={x}
                                                        style={{
                                                            width: "95vw",
                                                            height: "60vh",
                                                        }}
                                                        className="relative rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75"
                                                    >
                                                        <span
                                                            aria-hidden="false"
                                                            className="absolute inset-0"
                                                        >
                                                            <img
                                                                src={x}
                                                                alt=""
                                                                className="w-full h-full object-center  object-contain"
                                                            />
                                                        </span>
                                                        <span
                                                            aria-hidden="true"
                                                            className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* End Mobile Images */}
                            <div className="hidden sm:hidden md:flex flex-grow pr-6  xl:justify-center  top-14">
                                <div className="flex-auto flex-shrink max-w-screen-lg xl:max-w-screen-2xl">
                                    <div>
                                        {loaded ? null : (
                                            <div
                                                className=" flex bg-gray-400 animate-pulse flex-grow"
                                                style={{
                                                    objectFit: "contain",
                                                    minWidth: "100%",
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="bg-gray-800 rounded backdrop-filter	 backdrop-invert backdrop-blur-lg">
                                        <img
                                            style={
                                                loaded
                                                    ? {
                                                          //   height: "34.2rem",
                                                          width: "100%",
                                                          maxHeight: "85rem",
                                                          objectFit: "contain",
                                                      }
                                                    : { display: "none" }
                                            }
                                            onLoad={() => setLoaded(true)}
                                            src={selectedImage || product.image}
                                            alt={product.name}
                                            height="500"
                                            className="rounded filter drop-shadow-lg"
                                        ></img>
                                    </div>
                                    <div className=" hidden md:flex flex-grow-0 mt-9 ">
                                        <ul className="grid grid-cols-1 lg:grid-cols-2 lg:grid-flow-row-dense gap-4 lg:gap-8  ">
                                            {[...product.images].map((x) => (
                                                <li
                                                    key={x}
                                                    className="mx-1 bg-gray-800 rounded-md pb-4 md:pb-0"
                                                >
                                                    <img
                                                        src={x}
                                                        alt="product"
                                                        className=" rounded-2xl pt-2"
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-center  md:pt-16  w-full">
                                <ul className="productInfo">
                                    <li className="">
                                        <div className="relative font-medium text-3xl  break-words flex ">
                                            {product.name}

                                            <div
                                                style={{
                                                    borderTop:
                                                        "4px dashed #FC642D",
                                                    width: "60%",
                                                }}
                                                className="hidden md:block  absolute -bottom-3 right-0"
                                            ></div>
                                        </div>

                                        <div>
                                            <p className="text-orange-400 font-bold text-lg mt-4  md:text-right  md:text-black">
                                                {product.specs.len} x{" "}
                                                {product.specs.width} x{" "}
                                                {product.specs.height}
                                                cms
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <h1 className="text-2xl font-semibold pt-8">
                                            Personlize your Box
                                        </h1>

                                        <div>
                                            {product.personalisation && (
                                                <Suspense
                                                    fallback={
                                                        <>
                                                            <LoadingBox />
                                                        </>
                                                    }
                                                >
                                                    <PersonalizeBoxV4
                                                        personalised={
                                                            personalised
                                                        }
                                                        setPersonalised={
                                                            setPersonalised
                                                        }
                                                        typeOfProduct={
                                                            product.typeOfProduct
                                                        }
                                                        setPreview={setPreview}
                                                        preview={preview}
                                                        product={product}
                                                        refHTML={refHTML}
                                                    />
                                                </Suspense>
                                            )}
                                        </div>
                                    </li>

                                    {(product.trackInventory &&
                                        product.countInStock > 0) ||
                                    (!product.trackInventory &&
                                        product.inStock) ? (
                                        <>
                                            <li>
                                                <div>
                                                    <div className="flex flex-row items-center">
                                                        <p className="text-2xl font-semibold py-8 ">
                                                            Quantity
                                                        </p>
                                                    </div>
                                                    <div className="radio-btn-row ">
                                                        <div className="radio-btn-wrapper">
                                                            <button
                                                                className={
                                                                    buttonSelected ===
                                                                    1
                                                                        ? "radio-btn-selected"
                                                                        : "radio-btn"
                                                                }
                                                                onClick={() =>
                                                                    selectedWrapperFunc(
                                                                        1,
                                                                        1,
                                                                    )
                                                                }
                                                                type="button"
                                                            >
                                                                One <br />
                                                                <strong>
                                                                    ₹
                                                                    {preview
                                                                        ? product.price +
                                                                          100
                                                                        : product.price}
                                                                </strong>
                                                            </button>
                                                        </div>
                                                        <div className="radio-btn-wrapper ">
                                                            <button
                                                                className={
                                                                    buttonSelected ===
                                                                    2
                                                                        ? "radio-btn-selected"
                                                                        : "radio-btn"
                                                                }
                                                                onClick={() => {
                                                                    selectedWrapperFunc(
                                                                        3,
                                                                        2,
                                                                    );
                                                                }}
                                                                type="button"
                                                            >
                                                                Three <br />
                                                                <strong>
                                                                    ₹
                                                                    {preview
                                                                        ? product.price *
                                                                              3 +
                                                                          100 *
                                                                              3
                                                                        : product.price *
                                                                          3}
                                                                </strong>
                                                            </button>
                                                        </div>
                                                        <div className="radio-btn-wrapper">
                                                            <button
                                                                className={
                                                                    buttonSelected ===
                                                                    3
                                                                        ? "radio-btn-selected"
                                                                        : "radio-btn"
                                                                }
                                                                onClick={() =>
                                                                    selectedWrapperFunc(
                                                                        5,
                                                                        3,
                                                                    )
                                                                }
                                                                type="button"
                                                            >
                                                                Five <br />
                                                                <strong>
                                                                    ₹
                                                                    {preview
                                                                        ? product.price *
                                                                              5 +
                                                                          100 *
                                                                              5
                                                                        : product.price *
                                                                          5}
                                                                </strong>
                                                            </button>
                                                        </div>

                                                        <div className="radio-btn-wrapper">
                                                            <button
                                                                className={
                                                                    buttonSelected ===
                                                                    4
                                                                        ? "radio-btn-selected"
                                                                        : "radio-btn"
                                                                }
                                                                onClick={() =>
                                                                    selectedWrapperFunc(
                                                                        10,
                                                                        4,
                                                                    )
                                                                }
                                                                type="button"
                                                            >
                                                                <span className="relative inline-block">
                                                                    Ten <br />
                                                                    <strong>
                                                                        ₹
                                                                        {preview
                                                                            ? product.price *
                                                                                  10 +
                                                                              100 *
                                                                                  10
                                                                            : product.price *
                                                                              10}
                                                                    </strong>
                                                                    {/* <span className="text-white absolute -top-2 -right-8 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none  transform translate-x-1/2 -translate-y-1/2 bg-orange-450 rounded-full">
                                                                        %
                                                                    </span> */}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className=" pt-4 pb-1">
                                                        <button
                                                            className=" text-orange-500 hover:scale-105 hover:text-orange-400 hover:underline "
                                                            onClick={
                                                                showHideMoreQuatitySelectorHandler
                                                            }
                                                        >
                                                            Need More than that?
                                                        </button>
                                                        <Transition
                                                            show={
                                                                showHideMoreQuatitySelector
                                                                    ? true
                                                                    : false
                                                            }
                                                            enter="transform transition ease-in-out duration-300"
                                                            enterFrom="-translate-y-10 opacity-0"
                                                            enterTo=" translate-y-0 opacity-100 "
                                                            leave="transform transition ease-in-out duration-300"
                                                            leaveFrom="translate-y-0 opacity-100"
                                                            leaveTo="-translate-y-10 opacity-0"
                                                            as="div"
                                                            className=""
                                                        >
                                                            <div className="flex">
                                                                <QuantitySelector
                                                                    qty={qty}
                                                                    setQty={
                                                                        setQty
                                                                    }
                                                                    countInStock={
                                                                        product.countInStock
                                                                    }
                                                                    isStock={
                                                                        product.inStock
                                                                    }
                                                                    personalised={
                                                                        personalised
                                                                    }
                                                                    setButtonSelected={
                                                                        setButtonSelected
                                                                    }
                                                                    showHideMoreQuatitySelector={
                                                                        showHideMoreQuatitySelector
                                                                    }
                                                                />
                                                            </div>
                                                        </Transition>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <button onClick={executeScroll}>
                                                    <Rating
                                                        rating={product.rating}
                                                        numReviews={
                                                            product.numReviews
                                                        }
                                                    ></Rating>
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    type="button"
                                                    onClick={(e) =>
                                                        addToCartHandler(
                                                            e,
                                                            false,
                                                        )
                                                    }
                                                    disabled={loadingCart}
                                                    className="Button noselect w-full px-20 py-4 text-red-50  text-2xl font-semibold transition-colors duration-150 bg-orange-450 rounded-xl focus:shadow-outline hover:bg-orange-550"
                                                    style={{
                                                        height: "5.7rem",
                                                        borderRadius: "0.75rem",
                                                    }}
                                                >
                                                    {loadingCart ? (
                                                        <div className="py-3">
                                                            <LoadingBox loadingType="2"></LoadingBox>
                                                        </div>
                                                    ) : (
                                                        "Add to cart"
                                                    )}
                                                </button>
                                                <p className="mt-2 text-sm text-red-600 flex justify-center">
                                                    {loadingCart
                                                        ? "...Please wait confirming your customizations cart"
                                                        : "It only gets better from here"}
                                                </p>
                                            </li>
                                            <li>
                                                <section
                                                    aria-labelledby="details-heading"
                                                    className="mt-12"
                                                >
                                                    <h2
                                                        id="details-heading"
                                                        className="sr-only"
                                                    >
                                                        Additional details
                                                    </h2>

                                                    <div className="border-t divide-y divide-gray-200">
                                                        {details.map(
                                                            (detail) => (
                                                                <Disclosure
                                                                    as="div"
                                                                    key={
                                                                        detail.name
                                                                    }
                                                                >
                                                                    {({
                                                                        open,
                                                                    }) => (
                                                                        <>
                                                                            <h3>
                                                                                <Disclosure.Button className="group relative w-full py-6 flex justify-between items-center text-left">
                                                                                    <span
                                                                                        className={classNames(
                                                                                            open
                                                                                                ? "text-orange-450"
                                                                                                : "text-gray-900",
                                                                                            "text-lg font-medium",
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            detail.name
                                                                                        }
                                                                                    </span>
                                                                                    <span className="ml-6 flex items-center">
                                                                                        {open ? (
                                                                                            <MinusSmIcon
                                                                                                className="block h-6 w-6 text-orange-450 group-hover:text-orange-300"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        ) : (
                                                                                            <PlusSmIcon
                                                                                                className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        )}
                                                                                    </span>
                                                                                </Disclosure.Button>
                                                                            </h3>
                                                                            <Disclosure.Panel
                                                                                as="div"
                                                                                className="pb-6 prose prose-xl "
                                                                            >
                                                                                <ul role="list">
                                                                                    {detail.items.map(
                                                                                        (
                                                                                            item,
                                                                                        ) => (
                                                                                            <li
                                                                                                key={
                                                                                                    item
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    item
                                                                                                }
                                                                                            </li>
                                                                                        ),
                                                                                    )}
                                                                                </ul>
                                                                            </Disclosure.Panel>
                                                                        </>
                                                                    )}
                                                                </Disclosure>
                                                            ),
                                                        )}
                                                    </div>
                                                </section>
                                            </li>
                                        </>
                                    ) : (
                                        <div className="grid place-content-center">
                                            <h1 className="heading-1 danger">
                                                Out of Stock
                                            </h1>
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-around  ">
                        <div className="col-related border-t-0">
                            <h1 className="font-bold text-4xl">Description</h1>
                            <p className="break-words flex  pt-8">
                                {product.description}
                            </p>
                        </div>
                    </div>

                    <Suspense
                        fallback={
                            <>
                                <LoadingBox />
                            </>
                        }
                    >
                        <RelatedProductsComponent product={product} />
                        <ProductReviews
                            productId={productId}
                            product={product}
                            userInfo={userInfo}
                            commentRef={commentRef}
                        />
                    </Suspense>
                </div>
            )}
        </div>
    );
}

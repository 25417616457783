import React from "react";
import { Link } from "react-router-dom";

export default function NavbarSecondry(props) {
    return (
        <nav className="flex flex-col md:flex-row justify-around pl-8 pt-6 pr-4 w-full  border-b-2">
            <div className="flex flex-row justify-between md:justify-evenly">
                <h1 className=" hover:text-gray-400 text-3xl font-bold">
                    <Link to="/">{global.config.websiteName}</Link>
                </h1>
                <p className="flex items-center pl-2 md:pl-8 text-base text-gray-500">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                    &nbsp; Secure Checkout
                </p>
            </div>

            <div className="self-center mt-8  md:mt-0 ">
                <div className="row checkout-steps">
                    <div className={props.step1 ? "active" : ""}>Sign In</div>
                    <div className={props.step2 ? "active" : ""}>Shipping</div>
                    <div className={props.step3 ? "active" : ""}>Review</div>
                    <div className={props.step4 ? "active" : ""}>Payment</div>
                </div>
            </div>
        </nav>
    );
}

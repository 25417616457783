import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { deleteOrder, detailsOrder, payOrder } from "../actions/orderActions";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import {
    ORDER_DELIVER_RESET,
    ORDER_DETAILS_RESET,
    ORDER_PAY_RESET,
} from "../constants/orderConstants";

import NavbarSecondry from "../components/NavbarSecondry";
import Navbar from "../components/Navbar";
import Modal from "../components/Modal";
import Button from "../components/Button";
import { Disclosure } from "@headlessui/react";

export default function OrderScreen(props) {
    const orderId = props.match.params.id;
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;

    const redirect = props.location.search
        ? props.location.search.split("=")[1]
        : "/";

    useEffect(() => {
        if (!userInfo) {
            props.history.push(redirect);
        }
    }, [props.history, redirect, userInfo]);

    const [sdkReady, setSdkReady] = useState(false);
    const orderDetails = useSelector((state) => state.orderDetails);
    const { order, loading, error } = orderDetails;
    const [successRazor, setSuccessRazor] = useState(false);
    const [errorRazor, setErrorRazor] = useState(false);
    const [attemptedPay, setAttemptedPay] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalDescription, setModalDescription] = useState("");
    const orderPay = useSelector((state) => state.orderPay);
    const {
        loading: loadingPay,
        error: errorPay,
        success: successPay,
    } = orderPay;
    const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);

    /* Razor Pay */

    const addRazorPayScript = async () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = `https://checkout.razorpay.com/v1/checkout.js`;
            script.onload = () => {
                setSdkReady(true);
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
                setSdkReady(false);
            };
            document.body.appendChild(script);
        });
    };

    const displayRazorPay = async () => {
        const res = await addRazorPayScript();
        if (!res) {
            alert("RazorPay SDK failed to load. Are you Online ?");
            setSdkReady(false);
            return;
        }
        try {
            const responseRazor = await axios.post(
                `/api/razorpay/${order._id}/${order.totalPrice.toFixed()}`,
            );

            const data1 = responseRazor.data;
            // console.log("Response from /razorpay " + data1);
            // console.log("id on the front end " + data1.id);
            const options = {
                key: process.env.RAZORPAY_KEY_ID,
                name: `${global.config.websiteName}`,
                currency: data1.currency,
                amount: (order.totalPrice.toFixed() * 100).toString(),
                order_id: data1.id,
                description: "",
                image:
                    process.env.PUBLIC_URL +
                    "/assets/images/logoIndiekrateBox.png",
                handler: async function (response) {
                    //disable alerts
                    //alert(response.razorpay_payment_id);
                    //alert(response.razorpay_order_id);
                    //alert(response.razorpay_signature);

                    //const responseVerfi = await axios.post("/api/razorpay/verification/");
                    //const success = responseVerfi.data;
                    //console.log(" verfi frontend " + success);
                    try {
                        const paymentId = response.razorpay_payment_id;
                        const url = `/api/razorpay/capture/${paymentId}/${order.totalPrice.toFixed()}`;
                        const captureResponse = await axios.post(url, {});
                        const successObj = JSON.parse(captureResponse.data);
                        // console.log("successObj :" + successObj);
                        const captured = successObj.captured;
                        // console.log("captured :" + captured);
                        const capturedCheckFunc = () => {
                            if (captured) {
                                successPaymentHandler({
                                    id: response.razorpay_order_id,
                                    status: "COMPLETED",
                                    update_time: Date.now(),
                                    email_address: userInfo.email,

                                    razorpay_orderId:
                                        response.razorpay_order_id,
                                    razorpay_paymentId:
                                        response.razorpay_payment_id,
                                    razorpay_signature:
                                        response.razorpay_signature,
                                });
                                //window.location.replace(window.location.href);
                                //setPaymentSuccessModal(true);
                                setAttemptedPay(true);
                                setSuccessRazor(true);
                                //props.history.push(`/summary/${orderId}`);
                                // console.log("success");
                            } else {
                                alert(
                                    "Your payment failed. If amount has been deducted please contact us.",
                                );
                            }
                        };
                        capturedCheckFunc();
                    } catch (err) {
                        // console.log("Payment Error has been caught: " + err);
                    }
                },
                prefill: {
                    name: order.shippingAddress.fullName,
                    email: userInfo.email,
                    contact: userInfo.mobileNumber,
                },
            };
            const paymentObject = new window.Razorpay(options);
            // paymentObject.on("payment.failed", function (response) {
            //     alert(response.error.code);
            //     alert(response.error.description);
            //     alert(response.error.source);
            //     alert(response.error.step);
            //     alert(response.error.reason);
            //     alert(response.error.metadata.order_id);
            //     alert(response.error.metadata.payment_id);
            //     setErrorRazor(true);
            // });
            paymentObject.open();
        } catch (error) {
            // console.log(error);
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (
            !order ||
            successPay ||
            successRazor ||
            (order && order._id !== orderId)
        ) {
            dispatch({ type: ORDER_PAY_RESET });
            dispatch({ type: ORDER_DELIVER_RESET });
            dispatch({ type: ORDER_DETAILS_RESET });
            dispatch(detailsOrder(orderId));
        } else {
            if (!order.isPaid && !errorRazor) {
                var element = document.getElementById("script");
                if (typeof element != "undefined" && element != null) {
                    setSdkReady(true);
                } else {
                    addRazorPayScript();
                }
            }
        }
    }, [dispatch, order, orderId, sdkReady, successPay, successRazor]);

    useEffect(() => {
        if (successPay || successRazor) {
            dispatch({ type: ORDER_PAY_RESET });
            dispatch({ type: ORDER_DETAILS_RESET });
            setSuccessRazor(false);
            // props.history.push(`/order/${orderId}`);
            dispatch(detailsOrder(orderId));
        }
    }, [dispatch, order, orderId, props.history, successPay, successRazor]);
    const [locationKeys, setLocationKeys] = useState([]);
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === "PUSH") {
                setLocationKeys([location.key]);
            }

            if (history.action === "POP") {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);

                    // Handle forward event
                } else {
                    setLocationKeys((keys) => [location.key, ...keys]);
                    if (order && !order.isPaid) {
                        dispatch(deleteOrder(order._id));
                    } else {
                        props.history.push("/orderhistory");
                    }
                    // Handle back event
                }
            }
        });
    }, [dispatch, history, locationKeys, order, props.history]);

    useEffect(() => {
        return () => {
            // console.log("cleaned up");
        };
    }, []);

    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(order, paymentResult));
    };

    const successCODHandler = (codDetails) => {
        dispatch(payOrder(order, codDetails));
    };

    const personalisedHandler = (item) => {
        setModalDescription(
            <>
                <p>
                    <b> Message:</b>{" "}
                    {item.personalised?.message
                        ? `${item.personalised?.message}`
                        : " No message Added"}
                </p>
                <p>
                    <b>Image:</b>
                    {item.imageAdded === "true"
                        ? ` Image Added`
                        : " No Image Added"}
                </p>
            </>,
        );
        setModalIsOpen(true);
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const getDateHandler = (date) => {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const newDate = new Date(date);
        const getMonth = months[newDate.getMonth()];
        let time;
        if (newDate.getDate() === new Date().getDate()) {
            time = `${newDate.getHours()}:${newDate.getMinutes()}`;
        }

        const fomattedDate = `${getMonth} ${newDate.getDate()}, ${newDate.getFullYear()}`;
        return fomattedDate;
    };
    return loading ? (
        <LoadingBox loadingType="1"></LoadingBox>
    ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
    ) : (
        <div>
            {order.isPaid ? (
                <>
                    <Navbar></Navbar>

                    <Modal
                        modalType="2"
                        title="Payment Successful"
                        description="We’ve sent
										your an email with all of the details of your order."
                        paymentSuccessModal={paymentSuccessModal}
                        setPaymentSuccessModal={setPaymentSuccessModal}
                    />

                    <div className="flex md:justify-center ">
                        <Modal
                            modalIsOpen={modalIsOpen}
                            setModalIsOpen={setModalIsOpen}
                            title="Personalised Details"
                            description={modalDescription}
                        />
                    </div>
                    <div className="bg-white">
                        <nav
                            className="text-black font-bold my-8 pl-2"
                            aria-label="Breadcrumb"
                        >
                            <ol className="list-none p-0 inline-flex">
                                <li className="flex items-center hover:text-blue-400">
                                    <Link to="/account">Account</Link>
                                    <svg
                                        className="fill-current w-3 h-3 mx-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                    >
                                        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
                                    </svg>
                                </li>
                                <li className="flex items-center">
                                    <Link
                                        to="/orderhistory"
                                        className="text-black hover:text-blue-400"
                                    >
                                        Orders
                                    </Link>
                                    <svg
                                        className="fill-current w-3 h-3 mx-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                    >
                                        <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
                                    </svg>
                                </li>
                                <li className="flex items-center">
                                    <p className="text-black font-bold">
                                        Order Id : {order._id}
                                    </p>
                                </li>
                            </ol>
                        </nav>
                        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                            <h1 className="text-sm font-medium text-orange-450">
                                Payment successful
                            </h1>
                            <p className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                                {order?.step === 1
                                    ? `Thanks for ordering`
                                    : order?.step === 2
                                    ? "It's on the way!"
                                    : "Thanks for choosing Indiekrate"}
                            </p>
                            <p className="mt-2 text-base text-gray-500">
                                {order?.step === 1
                                    ? `We appreciate your order, we’re currently
                                processing it. So hang tight and we’ll send you
                                confirmation very soon!`
                                    : order?.step === 2
                                    ? "Your order has shipped and will be with you soon."
                                    : `We are always at your service, ${order.shippingAddress.fullName}`}
                            </p>

                            <div className="text-sm border-b border-gray-200 mt-8 pb-5 sm:flex sm:justify-between">
                                <dl className="flex">
                                    <dt className="text-gray-500">
                                        Order number&nbsp;
                                    </dt>
                                    <dd className="font-medium text-gray-900">
                                        {order._id}
                                    </dd>
                                    <dt>
                                        <span className="sr-only">Date</span>
                                        <span
                                            className="text-gray-400 mx-2"
                                            aria-hidden="true"
                                        >
                                            &middot;
                                        </span>
                                    </dt>
                                    <dd className="font-medium text-gray-900">
                                        <time dateTime="2021-03-22">
                                            {getDateHandler(order.paidAt)}
                                        </time>
                                    </dd>
                                </dl>
                                {/* <div className="mt-4 sm:mt-0">
                                    <a
                                        href="#"
                                        className="font-medium text-orange-450 hover:text-orange-500"
                                    >
                                        View invoice
                                        <span aria-hidden="true"> &rarr;</span>
                                    </a>
                                </div> */}
                            </div>

                            <p className="font-medium text-gray-900 mt-6 md:mt-10">
                                {order.isDelivered
                                    ? "Order deivered"
                                    : "Order placed"}{" "}
                                on{" "}
                                <time dateTime={order?.datetime}>
                                    {order.isDelivered
                                        ? getDateHandler(order.deliveredAt)
                                        : getDateHandler(order.paidAt)}
                                </time>
                            </p>
                            <div className="mt-6">
                                <div className="bg-gray-200 rounded-full overflow-hidden">
                                    <div
                                        className="h-2 bg-orange-450 rounded-full"
                                        style={{
                                            width: `calc((${
                                                order?.step === 3
                                                    ? 4
                                                    : order?.step
                                            } * 2 + 1) / 8 * 100%)`,
                                        }}
                                    />
                                </div>

                                <div className="hidden sm:grid grid-cols-4 font-medium text-gray-600 mt-6">
                                    <div className="text-orange-450">
                                        Order placed
                                    </div>
                                    <div
                                        className={classNames(
                                            order?.step > 0
                                                ? "text-orange-450"
                                                : "",
                                            "text-center",
                                        )}
                                    >
                                        Processing
                                    </div>
                                    <div
                                        className={classNames(
                                            order?.step > 1
                                                ? "text-orange-450"
                                                : "",
                                            "text-center",
                                        )}
                                    >
                                        Shipped
                                    </div>
                                    <div
                                        className={classNames(
                                            order?.step > 2
                                                ? "text-orange-450"
                                                : "",
                                            "text-right",
                                        )}
                                    >
                                        Delivered
                                    </div>
                                </div>
                            </div>

                            <div className="mt-8">
                                <h2 className="sr-only">Products purchased</h2>

                                <div className="space-y-24">
                                    {order.orderItems.map((item) => (
                                        <div
                                            key={item.id}
                                            className="bg-white border-t border-b border-gray-200 shadow-sm sm:border sm:rounded-lg"
                                        >
                                            <div className="py-6 px-4 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                                                <div className="sm:flex lg:col-span-7">
                                                    <div className="flex-shrink-0 w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-none sm:w-40 sm:h-40">
                                                        <img
                                                            src={item.image}
                                                            alt={item.name}
                                                            className="w-full h-full object-center object-contain sm:w-full sm:h-full"
                                                        />
                                                    </div>

                                                    <div className="mt-6 sm:mt-0 sm:ml-6">
                                                        <h3 className="text-base font-medium text-gray-900">
                                                            <Link
                                                                to={`/product/${item.slug}`}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        </h3>
                                                        <p className="mt-2 text-sm font-medium text-gray-900">
                                                            ₹{item.price}
                                                        </p>
                                                        <p className="mt-3 text-sm text-gray-500">
                                                            Dimensions:{" "}
                                                            {item.len} x{" "}
                                                            {item.width} x{" "}
                                                            {item.height} <br />
                                                            Material:{" "}
                                                            {item.material}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="mt-6 lg:mt-0 lg:col-span-5">
                                                    <dl className="grid grid-cols-2 gap-x-6 text-sm">
                                                        <div>
                                                            <dt className="font-medium text-gray-900"></dt>
                                                            <dd className="mt-3 text-gray-500">
                                                                {item.qty} x ₹
                                                                {item.price}= ₹
                                                                {item.qty *
                                                                    item.price}
                                                            </dd>
                                                        </div>
                                                        <div>
                                                            <dt className="font-medium text-gray-900"></dt>
                                                            <dd className="mt-3 text-gray-500 space-y-3">
                                                                <p>
                                                                    {item &&
                                                                        item.personalised && (
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        personalisedHandler(
                                                                                            item,
                                                                                        )
                                                                                    }
                                                                                    className="flex items-center text-blue-900 hover:text-blue-500"
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        height="15"
                                                                                        width="15"
                                                                                        viewBox="0 0 20 20"
                                                                                        fill="currentColor"
                                                                                        className="mr-2"
                                                                                    >
                                                                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                                                        <path
                                                                                            fillRule="evenodd"
                                                                                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                                                                            clipRule="evenodd"
                                                                                        />
                                                                                    </svg>
                                                                                    Personalised
                                                                                    Details
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                </p>
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Billing */}
                            <div className="mt-24">
                                <h2 className="sr-only">Billing Summary</h2>

                                <div className="bg-gray-50 rounded-lg py-6 px-6 lg:px-0 lg:py-8 lg:grid lg:grid-cols-12 lg:gap-x-8">
                                    <dl className="grid grid-cols-1 gap-6 text-sm sm:grid-cols-2 md:gap-x-8 lg:pl-8 lg:col-span-5">
                                        <div>
                                            <dt className="font-medium text-gray-900">
                                                Delivery address
                                            </dt>
                                            <dd className="mt-3 text-gray-500">
                                                <span className="block">
                                                    {
                                                        order.shippingAddress
                                                            .fullName
                                                    }
                                                </span>
                                                <span className="block">
                                                    {
                                                        order.shippingAddress
                                                            .addressLine1
                                                    }
                                                    ,
                                                    {order.shippingAddress
                                                        .addressLine2 ? (
                                                        <p>
                                                            {
                                                                order
                                                                    .shippingAddress
                                                                    .addressLine2
                                                            }
                                                            ,
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>

                                                <span className="block">
                                                    {order.shippingAddress.city}
                                                    ,{" "}
                                                    {
                                                        order.shippingAddress
                                                            .state
                                                    }
                                                    ,{" "}
                                                    {
                                                        order.shippingAddress
                                                            .country
                                                    }
                                                    ,{" "}
                                                    {
                                                        order.shippingAddress
                                                            .postalCode
                                                    }
                                                </span>
                                                {order.shippingAddress
                                                    .landmark ? (
                                                    <span className="block">
                                                        <strong>
                                                            Landmark:
                                                        </strong>{" "}
                                                        {
                                                            order
                                                                .shippingAddress
                                                                .landmark
                                                        }
                                                    </span>
                                                ) : null}
                                            </dd>
                                        </div>
                                        <div>
                                            <dt className="font-medium text-gray-900">
                                                Payment information
                                            </dt>
                                            <dd className="mt-3 flex">
                                                <div>
                                                    {/* <svg
                                            aria-hidden="true"
                                            width={36}
                                            height={24}
                                            viewBox="0 0 36 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-auto"
                                        >
                                            <rect
                                                width={36}
                                                height={24}
                                                rx={4}
                                                fill="#224DBA"
                                            />
                                            <path
                                                d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                                                fill="#fff"
                                            />
                                        </svg> */}
                                                    {/* <p className="sr-only">Visa</p> */}
                                                </div>
                                                <div className="">
                                                    <p className="text-gray-900">
                                                        {order.paymentMethod ===
                                                        "OnlinePayment"
                                                            ? "Online Payment"
                                                            : "Cash"}
                                                    </p>
                                                    {/* <p className="text-gray-600">
                                            Expires 02 / 24
                                        </p> */}
                                                </div>
                                            </dd>
                                        </div>
                                    </dl>

                                    <dl className="mt-8 divide-y divide-gray-200 text-sm lg:mt-0 lg:pr-8 lg:col-span-7">
                                        <div className="pb-4 flex items-center justify-between">
                                            <dt className="text-gray-600">
                                                Subtotal
                                            </dt>
                                            <dd className="font-medium text-gray-900">
                                                ₹{order.itemsPrice}
                                            </dd>
                                        </div>
                                        <div className="py-4 flex items-center justify-between">
                                            <dt className="text-gray-600">
                                                Shipping
                                            </dt>
                                            <dd className="font-medium text-gray-900">
                                                ₹{order.shippingPrice}
                                            </dd>
                                        </div>
                                        <div className="py-4 flex items-center justify-between">
                                            <dt className="text-gray-600">
                                                Tax
                                            </dt>
                                            <dd className="font-medium text-gray-900">
                                                ₹{order.taxPrice}
                                            </dd>
                                        </div>
                                        <div className="pt-4 flex items-center justify-between">
                                            <dt className="font-medium text-gray-900">
                                                Order total
                                            </dt>
                                            <dd className="font-medium text-orange-450">
                                                ₹{order.totalPrice}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <NavbarSecondry step1 step2 step3 step4></NavbarSecondry>
                    {/*  Start of new Payment Screen */}
                    <div className="bg-white">
                        {/* Background color split screen for large screens */}
                        <div
                            className="hidden lg:block fixed top-26 left-0 w-1/2 h-full bg-white"
                            aria-hidden="true"
                        />
                        <div
                            className="hidden lg:block fixed top-0 right-0 w-1/2 h-full bg-orange-450"
                            aria-hidden="true"
                        />

                        <div className="relative grid grid-cols-1 gap-x-16 max-w-8xl mx-auto lg:px-8 lg:grid-cols-2 lg:pt-16">
                            <h1 className="sr-only">Checkout</h1>
                            {/* Mobile order summary */}
                            <section
                                aria-labelledby="order-heading"
                                className="bg-orange-450 px-4 py-6 sm:px-6 lg:hidden"
                            >
                                <Disclosure
                                    as="div"
                                    className="max-w-2xl mx-auto"
                                    defaultOpen
                                >
                                    {({ open }) => (
                                        <>
                                            <div className="flex items-center justify-between">
                                                <h2
                                                    id="order-heading"
                                                    className="text-xl font-semibold text-white"
                                                >
                                                    Make your payment
                                                </h2>
                                                <Disclosure.Button className="font-medium text-white hover:text-gray-700">
                                                    {open ? (
                                                        <span>
                                                            Hide full summary
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            Show full summary
                                                        </span>
                                                    )}
                                                </Disclosure.Button>
                                            </div>

                                            <Disclosure.Panel>
                                                <ul
                                                    role="list"
                                                    className="divide-y divide-gray-200 border-b border-gray-200"
                                                >
                                                    {order.orderItems.map(
                                                        (product, index) => (
                                                            <li
                                                                key={index}
                                                                className="flex items-start py-6 space-x-4"
                                                            >
                                                                <img
                                                                    src={
                                                                        product.image
                                                                    }
                                                                    alt={
                                                                        product.name
                                                                    }
                                                                    className="flex-none w-28 h-28 rounded-md object-center object-contain bg-gray-50 "
                                                                />
                                                                <div className="flex-auto space-y-1 text-white">
                                                                    <h3 className="text-white">
                                                                        {
                                                                            product.name
                                                                        }
                                                                    </h3>
                                                                    <p>
                                                                        {
                                                                            product.material
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {
                                                                            product.len
                                                                        }{" "}
                                                                        x{" "}
                                                                        {
                                                                            product.width
                                                                        }{" "}
                                                                        x{" "}
                                                                        {
                                                                            product.height
                                                                        }{" "}
                                                                    </p>
                                                                </div>
                                                                <p className="flex-none text-base font-medium text-white">
                                                                    {
                                                                        product.qty
                                                                    }{" "}
                                                                    x ₹
                                                                    {
                                                                        product.price
                                                                    }{" "}
                                                                    = ₹
                                                                    {product.qty *
                                                                        product.price}
                                                                </p>
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>

                                                <dl className="text-sm font-medium text-red-100 mt-10 space-y-6">
                                                    <div className="flex justify-between">
                                                        <dt>Subtotal</dt>
                                                        <dd className="text-gray-100">
                                                            ₹{order.itemsPrice}
                                                        </dd>
                                                    </div>

                                                    <div className="flex justify-between">
                                                        <dt>Taxes</dt>
                                                        <dd className="text-gray-100">
                                                            ₹{order.taxPrice}
                                                        </dd>
                                                    </div>
                                                    <div className="flex justify-between">
                                                        <dt>Shipping</dt>
                                                        <dd className="text-gray-100">
                                                            ₹
                                                            {
                                                                order.shippingPrice
                                                            }
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </Disclosure.Panel>

                                            <p className="flex items-center justify-between text-sm font-semibold text-gray-100 border-t border-gray-100 pt-6 mt-6">
                                                <span className="text-base">
                                                    Total
                                                </span>
                                                <span className="text-base">
                                                    ₹{order.totalPrice}
                                                </span>
                                            </p>
                                            {!order.isPaid &&
                                                order.paymentMethod ===
                                                    "OnlinePayment" && (
                                                    <div className="flex items-center justify-between pt-6 mt-6 px-8">
                                                        {errorPay && (
                                                            <MessageBox variant="danger">
                                                                {error}
                                                            </MessageBox>
                                                        )}
                                                        {order.failedPay && (
                                                            <MessageBox variant="danger">
                                                                "There was an
                                                                issue with your
                                                                payment. Please
                                                                place the order
                                                                again"
                                                            </MessageBox>
                                                        )}

                                                        <Button
                                                            buttonName={`Pay ₹ ${order.totalPrice}`}
                                                            buttonType="button"
                                                            buttonClass="inline-flex items-center w-full px-6  border border-gray-300 shadow-sm text-lg font-bold rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition ease-in-out duration-700 transform scale-100  hover:scale-110"
                                                            loading={
                                                                loading ||
                                                                !sdkReady ||
                                                                attemptedPay
                                                            }
                                                            onClickHandler={
                                                                displayRazorPay
                                                            }
                                                            disabled={
                                                                attemptedPay ||
                                                                !sdkReady ||
                                                                order.failedPay
                                                            }
                                                        />
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </Disclosure>
                            </section>
                            {/* end mobile */}

                            <section
                                aria-labelledby="summary-heading"
                                className="hidden lg:block  absolute bg-orange-450 text-white py-12 md:px-10 lg:max-w-2xl lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24 lg:bg-transparent lg:row-start-1 lg:col-start-2"
                            >
                                <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
                                    <h2
                                        id="summary-heading"
                                        className="sr-only"
                                    >
                                        Order summary
                                    </h2>

                                    <dl>
                                        <dt className="text-sm font-medium">
                                            Amount due
                                        </dt>
                                        <dd className="mt-1 text-4xl font-extrabold text-white">
                                            ₹{order.totalPrice}
                                        </dd>
                                    </dl>

                                    <ul
                                        role="list"
                                        className="text-sm font-medium divide-y divide-white divide-opacity-10"
                                    >
                                        {order.orderItems.map(
                                            (product, index) => (
                                                <li
                                                    key={index}
                                                    className="flex items-start py-6 space-x-4"
                                                >
                                                    <img
                                                        src={product.image}
                                                        alt={product.name}
                                                        className="flex-none w-28 h-28 rounded-md object-center object-contain bg-gray-50 "
                                                    />
                                                    <div className="flex-auto space-y-1">
                                                        <h3 className="text-white">
                                                            {product.name}
                                                        </h3>
                                                        <p>
                                                            {product.material}
                                                        </p>
                                                        <p>
                                                            {product.len} x{" "}
                                                            {product.width} x{" "}
                                                            {product.height}{" "}
                                                        </p>
                                                    </div>
                                                    <p className="flex-none text-base font-medium text-white">
                                                        {product.qty} x ₹
                                                        {product.price} = ₹
                                                        {product.qty *
                                                            product.price}
                                                    </p>
                                                </li>
                                            ),
                                        )}
                                    </ul>

                                    <dl className="text-md font-medium space-y-6 border-t border-white border-opacity-40 pt-6">
                                        <div className="flex items-center justify-between">
                                            <dt>Subtotal</dt>
                                            <dd>₹{order.itemsPrice}</dd>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <dt>Shipping</dt>
                                            <dd>₹{order.shippingPrice}</dd>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <dt>Taxes</dt>
                                            <dd>₹{order.taxPrice}</dd>
                                        </div>

                                        <div className="flex items-center justify-between border-t border-white border-opacity-40 text-white pt-6">
                                            <dt className="text-lg">Total</dt>
                                            <dd className="text-lg">
                                                ₹{order.totalPrice}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                <div className="sticky bottom-0 p-4  ">
                                    {!order.isPaid &&
                                        order.paymentMethod ===
                                            "OnlinePayment" && (
                                            <div className="inline-flex justify-center items-center w-full pt-8">
                                                {errorPay && (
                                                    <MessageBox variant="danger">
                                                        {error}
                                                    </MessageBox>
                                                )}

                                                <Button
                                                    buttonName={`Pay ₹ ${order.totalPrice}`}
                                                    buttonType="button"
                                                    buttonClass=" w-full max-w-2xl px-6 py-3 border border-gray-300 shadow-sm text-lg font-bold rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition ease-in-out duration-700 transform scale-100  hover:scale-110"
                                                    loading={
                                                        loading || !sdkReady
                                                    }
                                                    onClickHandler={
                                                        displayRazorPay
                                                    }
                                                    disabled={
                                                        attemptedPay ||
                                                        !sdkReady
                                                    }
                                                />
                                            </div>
                                        )}
                                </div>
                            </section>
                            <section
                                aria-labelledby="payment-and-shipping-heading"
                                className="py-16 px-4 md:px-2 lg:max-w-4xl lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1"
                            >
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg  ">
                                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                        <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Contact No
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {
                                                        order.shippingAddress
                                                            .contactNo
                                                    }
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Shiping Address
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-4">
                                                    {
                                                        order.shippingAddress
                                                            .fullName
                                                    }
                                                    <br />
                                                    {
                                                        order.shippingAddress
                                                            .addressLine1
                                                    }
                                                    ,{" "}
                                                    {order.shippingAddress
                                                        .addressLine2 ? (
                                                        <p>
                                                            {
                                                                order
                                                                    .shippingAddress
                                                                    .addressLine2
                                                            }
                                                            ,{" "}
                                                        </p>
                                                    ) : null}
                                                    {order.shippingAddress.city}
                                                    ,{" "}
                                                    {
                                                        order.shippingAddress
                                                            .postalCode
                                                    }
                                                    ,{" "}
                                                    {
                                                        order.shippingAddress
                                                            .state
                                                    }
                                                    ,{" "}
                                                    {
                                                        order.shippingAddress
                                                            .country
                                                    }
                                                    {order.shippingAddress
                                                        .landmark ? (
                                                        <p>
                                                            <strong>
                                                                Landmark:
                                                            </strong>
                                                            {
                                                                order
                                                                    .shippingAddress
                                                                    .landmark
                                                            }
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* End of New Payment Screen */}
                </>
            )}
        </div>
    );
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";

export default class ErrorBoundary extends Component {
    state = {
        error: "",
        eventId: "",
        errorInfo: "",
        hasError: false,
    };
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        //console.log({ error, errorInfo });
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId, errorInfo });
        });
        this.setState({ errorInfo });
    }
    render() {
        const { hasError, errorInfo } = this.state;
        if (this.state.hasError) {
            return (
                <div className="grid place-items-center min-h-screen">
                    <div className="flex flex-col justify-center items-center gap-4">
                        <svg
                            className="w-64 h-full"
                            fill="none"
                            stroke="red"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                        <p>There was an error in loading this page. </p>
                        <button
                            onClick={() => {
                                window.location.reload();
                            }}
                            style={{ cursor: "pointer", color: "#0077FF" }}
                        >
                            Reload this page
                        </button>
                    </div>
                    <button
                        className="Button flex justify-center items-center gap-4 text-light"
                        onClick={() =>
                            Sentry.showReportDialog({
                                eventId: this.state.eventId,
                            })
                        }
                        style={{ backgroundColor: "gray" }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="30"
                            height="30"
                        >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                                fill="white"
                                d="M5.07 16A7.06 7.06 0 0 1 5 15v-1H3v-2h2v-1c0-.34.024-.673.07-1H3V8h2.674a7.03 7.03 0 0 1 2.84-3.072l-1.05-1.05L8.88 2.465l1.683 1.684a7.03 7.03 0 0 1 2.876 0l1.683-1.684 1.415 1.415-1.05 1.05A7.03 7.03 0 0 1 18.326 8H21v2h-2.07c.046.327.07.66.07 1v1h2v2h-2v1c0 .34-.024.673-.07 1H21v2h-2.674a7 7 0 0 1-12.652 0H3v-2h2.07zM9 10v2h6v-2H9zm0 4v2h6v-2H9z"
                            />
                        </svg>
                        Report feedback
                    </button>
                </div>
            );
        }

        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
};

import React from "react";
import { ReactComponent as LoadingIndiekrate } from "../assets/images/loadingIndiekrate.svg";

export default function LoadingBox(props) {
    const loadingType = props.loadingType;
    const loadingSwitch = (loadingType) => {
        switch (loadingType) {
            case "1":
                return (
                    <div className="loading w-full h-screen flex  items-center justify-center">
                        <div className="flex flex-col items-center">
                            <LoadingIndiekrate className="animate-bounce  w-40" />

                            <p>Loading</p>
                        </div>
                    </div>
                );
            case "2":
                return (
                    <>
                        <div className="flex justify-center items-center w-full">
                            <div className="loader-dots block relative w-20  h-5 mt-2 -ml-6 py-4">
                                <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-white"></div>
                                <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-white"></div>
                                <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-white"></div>
                                <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-white"></div>
                            </div>
                        </div>
                    </>
                );

            default:
                return (
                    <div>
                        <p>Loading...</p>
                    </div>
                );
        }
    };

    return (
        <div className="page-loading-animate">{loadingSwitch(loadingType)}</div>
    );
}

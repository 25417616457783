import React from "react";
import starFull from "../assets/images/star.svg";
import starHalf from "../assets/images/half-star.svg";
import starEmpty from "../assets/images/starEmpty.svg";

export default function Rating(props) {
    const { rating, numReviews, caption } = props;
    return (
        <div className="rating flex flex-row items-center">
            <span>
                <img
                    className=""
                    alt="⭐️"
                    width="12px"
                    src={
                        rating >= 1
                            ? starFull
                            : rating >= 0.5
                            ? starHalf
                            : starEmpty
                    }
                ></img>
            </span>
            <span>
                <img
                    alt="⭐️"
                    width="12px"
                    src={
                        rating >= 2
                            ? starFull
                            : rating >= 1.5
                            ? starHalf
                            : starEmpty
                    }
                ></img>
            </span>
            <span>
                <img
                    alt="⭐️"
                    width="12px"
                    src={
                        rating >= 3
                            ? starFull
                            : rating >= 2.5
                            ? starHalf
                            : starEmpty
                    }
                ></img>
            </span>
            <span>
                <img
                    alt="⭐️"
                    width="12px"
                    src={
                        rating >= 4
                            ? starFull
                            : rating >= 3.5
                            ? starHalf
                            : starEmpty
                    }
                ></img>
            </span>
            <span>
                <img
                    alt="⭐️"
                    src={
                        rating >= 5
                            ? starFull
                            : rating >= 4.5
                            ? starHalf
                            : starEmpty
                    }
                    width="12px"
                ></img>
            </span>
            {caption ? (
                <span>{caption}</span>
            ) : (
                <span>
                    <span className="relative inline-block">
                        <svg
                            className="w-6 h-6 text-gray-700 fill-current"
                            viewBox="0 0 20 20"
                        >
                            <path
                                d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
                                clipRule="evenodd"
                                fillRule="evenodd"
                            ></path>
                        </svg>
                        <span
                            style={{ fontSize: "8px" }}
                            className=" absolute -top-1 -right-1 inline-flex items-center justify-center px-2 py-1 font-bold leading-none  transform translate-x-1/2 -translate-y-1/2 bg-orange-450 rounded-full"
                        >
                            <p className="text-red-50">{numReviews}</p>
                        </span>
                    </span>
                </span>
            )}
        </div>
    );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CART_ADD_ITEM_SUCCESS_RESET } from "../constants/cartConstants";
import { Transition } from "@headlessui/react";
import { XIcon, ShoppingCartIcon } from "@heroicons/react/outline";
export default function Notifications(props) {
    const [notifications, setNotifications] = useState(false);

    const cart = useSelector((state) => state.cart);
    const { success } = cart;

    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            console.log("cart success");
            setNotifications(true);
            setTimeout(() => {
                setNotifications(false);
                dispatch({ type: CART_ADD_ITEM_SUCCESS_RESET, success: false });
            }, 6000);
        }
        return () => {};
    }, [success]);

    return (
        <Transition
            show={notifications}
            enter="transform transition ease-in-out duration-400"
            enterFrom=" translate-x-full"
            enterTo=" translate-x-0"
            leave="transform transition ease-in-out duration-200"
            leaveFrom=" translate-x-0"
            leaveTo=" translate-x-full"
            as="div"
            className="fixed top-20 right-8 z-40 hidden md:flex flex-col shadow-lg rounded-xl bg-white border-2 transform transition ease-in-out hover:border-orange-450 mx-auto m-8 p-4 notification-box"
        >
            <div className=" pb-2 flex justify-between items-center">
                <h1 className="text-sm uppercase font-normal text-gray-500 flex gap-2 items-center">
                    <ShoppingCartIcon className="fill-current text-orange-450 h-7 w-7 " />
                    Cart notification
                </h1>
                <button
                    className="float-right rounded-full p-1  hover:bg-gray-300 transform transition ease-in-out hover:scale-x-95 "
                    onClick={() => setNotifications(false)}
                    type="button"
                >
                    <XIcon className="fill-current text-gray-600 h-7 w-7 " />
                </button>
            </div>

            <Link
                to="/cart"
                onClick={() => setNotifications(false)}
                className="text-sm text-black tracking-tight"
            >
                Your personalised box has been added to the cart! <br /> You can
                check out by clicking here.
            </Link>
        </Transition>
    );
}

import { Fragment, lazy, Suspense, useEffect, useState } from "react";
import { Menu, Transition, Popover } from "@headlessui/react";
import { Link, Route, useLocation } from "react-router-dom";
import SearchBox from "./SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../actions/userActions";
import decode from "jwt-decode";
import SlideOver from "./SlideOver";

import {
    MenuIcon,
    SearchIcon,
    UserIcon,
    XIcon,
} from "@heroicons/react/outline";

const SignInModal = lazy(() => import("./SignInModal"));
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
    const [isOpenSignIn, setIsOpenSignIn] = useState(false);
    const [registerOrSigin, setRegisterOrSigin] = useState(1);
    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    const dispatch = useDispatch();
    const signoutHandler = () => {
        dispatch(signout());
    };
    const [searchActive, setSearchActive] = useState(false);
    useEffect(() => {
        const token = userInfo?.token;
        if (token) {
            const decodedToken = decode(token);
            if (decodedToken.exp * 1000 < new Date().getTime()) {
                signoutHandler();
            }
        }
    }, []);
    const slideOverHandler = (e) => {
        e.preventDefault();
        setSidebarIsOpen(!sidebarIsOpen);
    };

    return (
        <header className="md:relative bg-white sticky top-0 z-50">
            <Popover>
                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 ">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-end gap-2">
                                        <img
                                            className="h-12 w-auto"
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/logo.svg"
                                            }
                                            alt="{global.config.websiteName} Logo"
                                        />
                                        {global.config.websiteName}
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500">
                                            <span className="sr-only">
                                                Close menu
                                            </span>
                                            <XIcon
                                                className="h-8 w-8"
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <nav className="grid grid-cols-1 gap-7">
                                        <Popover.Button>
                                            <Link
                                                to="/search/name"
                                                className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-orange-450 to-red-300 text-white"></div>
                                                <div className="ml-4 text-base font-medium text-gray-900">
                                                    Shop
                                                </div>
                                            </Link>
                                        </Popover.Button>
                                        <Popover.Button>
                                            <Link
                                                to="/about"
                                                className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-orange-450 to-red-300 text-white"></div>
                                                <div className="ml-4 text-base font-medium text-gray-900">
                                                    About
                                                </div>
                                            </Link>
                                        </Popover.Button>

                                        <Popover.Button>
                                            <Link
                                                to="/contact"
                                                className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                            >
                                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-orange-450 to-red-300 text-white"></div>
                                                <div className="ml-4 text-base font-medium text-gray-900">
                                                    Contact
                                                </div>
                                            </Link>
                                        </Popover.Button>
                                        {userInfo && (
                                            <Popover.Button>
                                                <Link
                                                    to="/orderhistory"
                                                    className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                                                >
                                                    <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-orange-450 to-red-300 text-white"></div>
                                                    <div className="ml-4 text-base font-medium text-gray-900">
                                                        Order History
                                                    </div>
                                                </Link>
                                            </Popover.Button>
                                        )}
                                    </nav>
                                </div>
                            </div>
                            <div className="py-6 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    <Popover.Button className="text-base font-medium text-gray-900 hover:text-gray-700">
                                        <Link to="/about">About</Link>
                                    </Popover.Button>
                                    <Popover.Button className="text-base font-medium text-gray-900 hover:text-gray-700">
                                        <Link to="/contact">Contact Us</Link>
                                    </Popover.Button>
                                    <Popover.Button className="text-base font-medium text-gray-900 hover:text-gray-700">
                                        <Link to="/search/name">Shop</Link>
                                    </Popover.Button>
                                </div>
                                <div className="mt-6">
                                    {userInfo ? null : (
                                        <>
                                            <button
                                                onClick={() => {
                                                    setIsOpenSignIn(true);
                                                    setRegisterOrSigin(2);
                                                }}
                                                className="w-full flex items-center justify-center bg-gradient-to-r from-orange-450 to-orange-500 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-orange-300 hover:to-orange-300"
                                            >
                                                Sign up
                                            </button>
                                            <p className="mt-6 text-center text-base font-medium text-gray-500">
                                                Existing customer?
                                                <button
                                                    onClick={() => {
                                                        setIsOpenSignIn(true);
                                                        setRegisterOrSigin(1);
                                                    }}
                                                    className="text-gray-900"
                                                >
                                                    Sign in
                                                </button>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
                <nav
                    aria-label="Top"
                    className=" max-w-9xl mx-auto px-4 sm:px-6 lg:px-8"
                >
                    <div className="border-b border-gray-200 py-4 ">
                        <div className="h-16 flex items-center justify-between">
                            <div className="flex-1 flex items-center lg:hidden">
                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-450">
                                    <span className="sr-only">Open menu</span>
                                    <MenuIcon
                                        className="h-8 w-8"
                                        aria-hidden="true"
                                    />
                                </Popover.Button>

                                <button
                                    onClick={() => setSearchActive(true)}
                                    className="inline-flex  ml-2 p-2 text-gray-400 hover:text-gray-500"
                                >
                                    <span className="sr-only">Search</span>
                                    <SearchIcon
                                        className="h-8 w-8"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>

                            {/* Pages */}

                            <div className="hidden lg:flex-1 lg:block lg:self-stretch">
                                <div className="h-full flex space-x-8">
                                    <Link
                                        to="/about"
                                        className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                                    >
                                        About
                                    </Link>

                                    <Link
                                        to="/contact"
                                        className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                                    >
                                        Contact Us
                                    </Link>
                                    <Link
                                        to="/search/name"
                                        className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                                    >
                                        Shop
                                    </Link>
                                </div>
                            </div>

                            {/* Logo */}
                            <Link
                                to="/"
                                className="flex items-center gap-4 text-lg font-semibold"
                            >
                                <span className="sr-only">
                                    {global.config.websiteName}
                                </span>
                                <img
                                    className="h-12 w-auto"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/logo.svg"
                                    }
                                    alt="Indiekrate Logo"
                                />{" "}
                                {global.config.websiteName}
                            </Link>

                            <div className="flex-1 flex items-center justify-end">
                                {/* Search */}
                                <button
                                    onClick={() => setSearchActive(true)}
                                    className="hidden ml-6 p-2 text-gray-400 hover:text-gray-500 lg:block"
                                >
                                    <span className="sr-only">Search</span>
                                    <SearchIcon
                                        className="h-8 w-8"
                                        aria-hidden="true"
                                    />
                                </button>

                                {/* Account */}

                                <div className="pr-2 hover:text-gray-400 text-white">
                                    <Menu
                                        as="div"
                                        className="relative inline-block text-left"
                                    >
                                        {({ open }) => (
                                            <>
                                                <div>
                                                    <Menu.Button className="inline-flex justify-center w-full rounded-full  p-2 text-gray-400 hover:text-gray-500 lg:ml-4 bg-white text-sm font-medium  group  focus:outline-none focus:ring-2 focus:ring-offset-1  focus:ring-orange-450">
                                                        <span className="text-white absolute right-0 top-3 inline-flex items-center justify-center px-2 py-2  text-xs font-bold leading-none   transform translate-x-1/2 -translate-y-1/2 bg-orange-450 rounded-full"></span>
                                                        <span className="sr-only">
                                                            Account
                                                        </span>
                                                        <UserIcon
                                                            className="h-8 w-8"
                                                            aria-hidden="true"
                                                        />
                                                    </Menu.Button>
                                                </div>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                                                    >
                                                        <div className="py-1">
                                                            {userInfo ? (
                                                                <>
                                                                    <Menu.Item>
                                                                        {({
                                                                            active,
                                                                        }) => (
                                                                            <Link
                                                                                to="/account"
                                                                                className={classNames(
                                                                                    active
                                                                                        ? "bg-gray-100 text-gray-900"
                                                                                        : "text-gray-700",
                                                                                    "block px-4 py-2 text-sm",
                                                                                )}
                                                                            >
                                                                                Your
                                                                                Account
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({
                                                                            active,
                                                                        }) => (
                                                                            <Link
                                                                                to="/orderhistory"
                                                                                className={classNames(
                                                                                    active
                                                                                        ? "bg-gray-100 text-gray-900"
                                                                                        : "text-gray-700",
                                                                                    "block px-4 py-2 text-sm",
                                                                                )}
                                                                            >
                                                                                Order
                                                                                History
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({
                                                                            active,
                                                                        }) => (
                                                                            <Link
                                                                                to="#signout"
                                                                                className={classNames(
                                                                                    active
                                                                                        ? "bg-gray-100 text-gray-900"
                                                                                        : "text-red-500",
                                                                                    "block px-4 py-2 text-sm",
                                                                                )}
                                                                                onClick={
                                                                                    signoutHandler
                                                                                }
                                                                            >
                                                                                Sign
                                                                                Out
                                                                            </Link>
                                                                        )}
                                                                    </Menu.Item>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Menu.Item>
                                                                        {({
                                                                            active,
                                                                        }) => (
                                                                            <button
                                                                                onClick={() => {
                                                                                    setIsOpenSignIn(
                                                                                        true,
                                                                                    );
                                                                                    setRegisterOrSigin(
                                                                                        1,
                                                                                    );
                                                                                }}
                                                                                className={classNames(
                                                                                    active
                                                                                        ? "bg-gray-100 text-gray-900"
                                                                                        : "text-gray-700",
                                                                                    "block px-4 py-2 text-sm w-full text-left",
                                                                                )}
                                                                            >
                                                                                Sign
                                                                                In
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        {({
                                                                            active,
                                                                        }) => (
                                                                            <button
                                                                                onClick={() => {
                                                                                    setIsOpenSignIn(
                                                                                        true,
                                                                                    );
                                                                                    setRegisterOrSigin(
                                                                                        2,
                                                                                    );
                                                                                }}
                                                                                className={classNames(
                                                                                    active
                                                                                        ? "bg-gray-100 text-gray-900"
                                                                                        : "text-gray-700",
                                                                                    "block px-4 py-2 text-sm w-full text-left",
                                                                                )}
                                                                            >
                                                                                Register
                                                                            </button>
                                                                        )}
                                                                    </Menu.Item>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                </div>

                                {/* Cart */}
                                <div className="ml-4 flow-root lg:ml-6">
                                    <button
                                        type="button"
                                        className="group -m-2 p-2 flex items-center"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            slideOverHandler(event);
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                            className="flex-shrink-0 h-10 w-10 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        >
                                            <title>Cart</title>
                                            <circle
                                                cx="176"
                                                cy="416"
                                                r="16"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="32"
                                            />
                                            <circle
                                                cx="400"
                                                cy="416"
                                                r="16"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="32"
                                            />
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="32"
                                                d="M48 80h64l48 272h256"
                                            />
                                            <path
                                                d="M160 288h249.44a8 8 0 007.85-6.43l28.8-144a8 8 0 00-7.85-9.57H128"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="32"
                                            />
                                        </svg>
                                        <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                                            {cartItems.length > 0
                                                ? cartItems.length
                                                : 0}
                                        </span>
                                        <span className="sr-only">
                                            items in cart, view bag
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </Popover>
            <Suspense fallback={null}>
                <SignInModal
                    isOpen={isOpenSignIn}
                    setIsOpen={setIsOpenSignIn}
                    registerOrSigin={registerOrSigin}
                    setRegisterOrSigin={setRegisterOrSigin}
                    location={location}
                />
            </Suspense>
            <SlideOver open={sidebarIsOpen} setOpen={setSidebarIsOpen} />
            <Route
                render={({ history }) => (
                    <SearchBox
                        history={history}
                        setSearchActive={setSearchActive}
                        searchActive={searchActive}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                    />
                )}
            />
        </header>
    );
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { resetPassword, signin } from "../actions/userActions";
import MessageBox from "../components/MessageBox";
import "../styles/signin.css";
import Button from "../components/Button";

export default function ResetPasswordScreen(props) {
    const token = new URLSearchParams(window.location.search).get("token");
    console.log(token);

    const initialState = { password: "", repeatPassword: "" };
    const [formData, setFormData] = useState(initialState);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const passwordReset = useSelector((state) => state.passwordReset);
    const { success, loading, error } = passwordReset;

    const dispatch = useDispatch();
    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(resetPassword(formData, token));
    };

    useEffect(() => {
        if (!token) {
            props.history.push("/");
        }
    }, [props.history, token]);

    useEffect(() => {
        if (success) {
            window.alert(
                "passowrd reset successfull. you will be redirected to login again",
            );
            props.history.push("/");
        }
    }, [props.history, success]);

    return (
        <div>
            <nav className="flex items-center justify-start pl-8 py-4 w-full  border-b-2">
                <h1 className=" hover:text-gray-400 text-3xl font-bold">
                    <Link to="/">{global.config.websiteName}</Link>
                </h1>
            </nav>
            <form
                className="form-signin"
                style={{ marginTop: "10rem", marginBottom: "10rem" }}
                onSubmit={submitHandler}
            >
                <div className="h1-message">
                    <h1>Reset Password</h1>
                    <p className="p-message">Enter your new password.</p>
                </div>
                {error && (
                    <div>
                        <MessageBox varient="danger">{error}</MessageBox>
                    </div>
                )}

                <div className="field">
                    <input
                        className="input-signin"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter your new password"
                        autoComplete="new-password"
                        required
                        onChange={handleChange}
                    />
                    <label className="label-signin" htmlFor="password">
                        New Password
                    </label>
                </div>
                <div className="field">
                    <input
                        className="input-signin"
                        type="password"
                        name="repeatPassword"
                        id="repeatPassword"
                        placeholder="Repeat your new password"
                        autoComplete="new-password"
                        required
                        onChange={handleChange}
                    />
                    <label className="label-signin" htmlFor="repeatPassword">
                        Repeat Password
                    </label>
                </div>

                <div className="button-center">
                    <Button
                        buttonName="Confirm"
                        buttonType="submit"
                        buttonClass="login-button"
                        loading={loading}
                        disabled={loading}
                    />
                </div>

                <p className="p-message">
                    Don’t need to reset? &nbsp;
                    <Link to={`/signin`} className="text-orange-450">
                        Signin Here
                    </Link>
                </p>
            </form>
        </div>
    );
}
